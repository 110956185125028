<template>
  <!-- 文章搜索v-if="item.sourceName != null && item.filterName != null" -->
  <div>
    <a-spin :spinning="loading">
      <div class="main">
        <div class="search_article" v-for="item in msg ? msg.results : []" :key="item.id">
          <div>
            <div v-if="item.sourceName != null && item.filterName != null" class="title">
              {{ item.sourceName + '：' }}{{ item.typeName ? item.typeName : item.filterName }}
              <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
            </div>
            <div :class="item.sourceName != null && item.filterName != null ? 'title_s' : 'title_s2'">
              <a v-if="keyId === '9'" :href="'/article-info?id=' + item.id + '&share=1&showType=clinical'" @click.prevent="article2(item)">
                <RichText :content="item.title | replaceHandle(query)" />
              </a>
              <a v-else :href="'/article-info' + generate(item.permalinks)" @click.prevent="article(item.permalinks)">
                <RichText :content="item.title | replaceHandle(query)" />
              </a>
            </div>
            <div class="title_e">
              <div>
                <RichText :content="item.authorText"  />
                <i v-if="item.ifCollect != 0 && keyId === '9'" class="iconfont icon-shanchu" @click="collect(item)"></i>
              </div>
            </div>
            <div v-if="item.intro == null || item.intro == ''"></div>
            <div v-else class="introContainer">
              <a v-if="keyId === '9'" :href="'/article-info?id=' + item.id + '&share=1&showType=clinical'" @click.prevent="article2(item)" class="content">
                <RichText :content="item.intro | replaceHandle(query)" />
              </a>
              <a v-else :href="'/article-info' + generate(item.permalinks)" @click.prevent="article(item.permalinks)" class="content">
                <RichText :content="item.intro | replaceHandle(query)" />
              </a>
            </div>
            <div class="other">
              <div v-if="item.wantsay != '' && keyId !== '9'" class="shuPing">述评</div>
              <a @click.prevent="onItemClick" class="other1" v-for="(value, index) in item.classNames" :key="index">
                {{ value }}
              </a>
              <span v-if="item.classNames == null"></span>
              <span v-else>|</span>
              <!-- <span v-if="item.classNames.length != 0">|</span> -->
              {{ item.postdate }}
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <div v-if="length == '0' && !loading" style="display: flex; justify-content: center">
      <el-image :src="nulldata" fit="cover"></el-image>
    </div>
    <div class="footer" v-if="length != '0' && !loading">
      <div style="display: flex">
        <a-button type="link" @click="onShowSizeChange(1, pageSize)">首页</a-button>
        <a-pagination v-model="pageNo" :page-size="pageSize" show-size-changer :page-size-options="['10', '20', '50', '100']" :total="msg ? msg.page.total : 0" show-less-items
          @change="onShowSizeChange" @showSizeChange="onShowSizeChange" />
        <a-button type="link" @click="onShowSizeChange(msg.page.pages, pageSize)">尾页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import nulldata from '@/assets/imgs/nulldata.png'
import { generateHref } from '@/utils/utils.js'
import RichText from '@/components/layout/RichText.vue'
import { replaceHandle } from '@/filters'
import { mapState } from 'vuex'
import { collectArticle, cancelCollect } from '@/api/user-api.js'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'SearchAnimation',
  components: { RichText },
  props: {
    msg: {
      type: Object,
      default: function () {
        return null
      },
    },
    query: {
      type: String,
      default() {
        return ''
      },
    },
    pageNo: {
      type: Number,
      default() {
        return 1
      },
    },
    loading: {},
    keyId: {},
  },
  data() {
    return {
      // pageNo: 1,
      pageSize: 20,
      length: 0,
      nulldata,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  filters: { replaceHandle },
  created() {
    this.$bus.on('selectArticleListAmount', (res) => {
      this.length = res.length
    })
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onShowSizeChange(page, size) {
      console.log(page, size)
      this.pageNo = page
      this.pageSize = size
      this.$emit('paging', page, size)
    },
    animation(id) {
      this.$router.push({ path: '/animation-particulars', query: { permalinks: id } })
    },
    onItemClick(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    article(id) {
      this.$router.push({ path: '/article-info', query: { permalinks: id } })
    },
    article2(item) {
      this.$router.push({ path: '/article-info', query: { id: item.id, share: 1, showType: 'clinical' } })
    },
    async collect(item) {
      if (this.user) {
        if (item.ifCollect === 0) {
          //收藏
          await collectArticle({ id: item.id })
          this.onShowSizeChange(this.pageNo, this.pageSize)
        } else {
          //取消收藏
          let res = await cancelCollect({ id: item.id })
          if (res) {
            this.onShowSizeChange(this.pageNo, this.pageSize)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 18px;
}
.mobile {
  .main {
    display: block;
    padding: 0 15px;
  }
}
.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
}
.search_article {
  width: 100%;
  // margin: 20px auto;
  // height: 200px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 32px;
  div {
    .title_s {
      /deep/.rich-text {
        margin-top: 8px;
        font-size: 18px !important;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
      }
      /deep/.rich-text:hover {
        color: #e52e00;
      }
      a {
        position: relative;
      }
    }
    .introContainer {
      .content {
        /deep/.rich-text {
          font-size: 14px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        /deep/.rich-text:hover {
          color: #e52e00;
        }
      }
    }
  }
}
.mobile {
  .search_article {
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}
.title {
  font-size: 18px;
  // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
  line-height: 30px;
  color: #e52e00;
  i {
    float: right;
    cursor: pointer;
    display: none;
  }
}
.title_s {
  margin-top: 8px;
  font-size: 18px;
  // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
  color: rgba(0, 0, 0, 0.85);
  line-height: 26px;
}

.title_s2 {
  font-size: 18px;
  // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
  color: rgba(0, 0, 0, 0.85);
  line-height: 26px;
}
.title_e {
  margin-top: 8px;
  font-size: 14px;
  // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  i {
    float: right;
    cursor: pointer;
    display: none;
  }
  i:hover {
    color: #e52e00;
  }
}
.content {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  // width: 100%;
  // height: 40px;
  // overflow: hidden;
}
.other {
  margin-top: 8px;
  font-size: 14px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  .shuPing {
    display: inline-block;
    background-color: #e52e00;
    padding: 0rem 0.5rem;
    border-radius: 1rem;
    color: #fff;
  }
}
.other1 {
  margin-top: 8px;
  margin-right: 1px;
  font-size: 14px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}
</style>
