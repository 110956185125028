// 动画解读
export const PATH_ANIMATION_LIST = '/animation-list'
//动画解读详情
export const PATH_ANIMATION_PARTICULARS = '/animation-particulars'
// 文章详情
export const PATH_ARTICLE_INFO = '/article-info'
// 音频
export const PATH_AUDIO_LIST = '/audio-list'
// 音频详情
export const PATH_AUDIO_PARTICULARS = '/audio-particulars'
// 临床研究论文
export const PATH_CLINICAL_RESEARCH_LIST = '/clinical-research-list'
// 课程
export const PATH_COURSE_RESOURCES = '/course-resources'
// 课程视频
export const PATH_COURSE_VIDEO = '/course-video'
// 会议预告
export const PATH_MEETING_NOTICE = '/meeting-notice'
// 会议回顾
export const PATH_MEETING_REVIEW = '/meeting-review'
// 系列课
export const PATH_SERIES_COURSES = '/series-courses'
// 按期浏览
export const PATH_PERIOD_ARTICLE = '/period-article'
// 最新期刊
export const PATH_LATEST_PERIODICAL = '/latest-periodical'
// NEJM目录
export const PATH_NEJM_CATALOG = '/nejm-catalog'
// NEJM类型文章
export const PATH_NEJM_LIST = '/nejm-list'
// Catalyst
export const PATH_NEJM_CATALYST_LIST = '/nejm-catalyst-list'
//Evidence
export const PATH_NEJM_EVIDENCE_LIST = '/nejm-evidence-list'
// NEJM期刊荟萃
export const PATH_NEJM_PERIODICAL_LIST = '/nejm-periodical-list'
// 专业领域
export const PATH_PROFESSIONAL_ARTICLE_LIST = '/professional-article-list'
// 专业领域更多
export const PATH_PROFESSIONAL_MORE = '/professional-more'
// 路由冲突中转
export const PATH_TRANSFER = '/article-transfer'
// 用户注册
export const PATH_REGISTER = '/register'
// 述评
export const PATH_COMMENTARY = '/commentary'
