<template>
  <MainCenter>
    <el-button v-if="$isMobile" @click="drawer = true" class="shaiXuanMore" type="primary">筛选</el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <div class="drawerContainer">
        <div class="drawer1">
          <van-cell title="日期" :value="timeFit" @click="show = true" />
          <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="onParamChange2" />
        </div>
      </div>
    </el-drawer>

    <TitleFormList
      title="临床研究论文撰写"
      sub-title="Publication of clinical research"
      :data-source="list"
      :pagination="flag === true ? pagination : null"
      :loading="loading"
    >
      <el-form v-if="!$isMobile" :model="form" class="form" slot="form" label-position="top">
        <a-anchor :offsetTop="!$isMobile ? 112 : 62">
          <el-form-item label="日期" prop="period">
            <el-date-picker
              unlink-panels
              v-model="form.period"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 240px"
              format="yyyy-MM-dd"
              value-format="yyyyMMdd"
              @change="onParamChange"
            />
          </el-form-item>
        </a-anchor>
      </el-form>
      <div class="item" slot-scope="{ item }">
        <div class="item-title">
          <a :href="'/article-info' + generate(item.id)" @click.prevent="onItemClick(item)">
            {{ item.title }}
          </a>
        </div>
        <div class="item-desc">{{ item.intro }}</div>
        <div class="fr">
          <div class="item-type"><RichText :content="item.author"  /></div>
          <div class="item-date">{{ item.postdate | formatDateTxt }}</div>
        </div>
      </div>
    </TitleFormList>
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import RichText from '@/components/layout/RichText.vue'
import TitleFormList from '@/components/layout/TitleFormList.vue'
import { selectArticleList } from '@/api/article'
import { format } from '@/utils/time-utils'
import moment from 'moment'

export default {
  name: 'ClinicalResearchList',
  components: { RichText, TitleFormList, MainCenter },
  data() {
    return {
      list: [],
      pagination: {
        onChange: this.onPageChange,
        showSizeChanger: false,
        // size: 'small',
        page: 1,
        pageSize: 20,
        total: 0,
        current: 1,
      },
      form: {
        period: null,
      },
      flag: false,
      loading: true,
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
    }
  },
  filters: {
    formatDateTxt(time) {
      return format(time, 'YYYY-MM-DD')
    },
  },
  async created() {
    await this.getList()
  },
  watch: {
    '$route.query': {
      handler() {
        if(this.$route.query.startDate && this.$route.query.endDate){
          this.form.period = [ this.$route.query.startDate, this.$route.query.endDate];
          this.timeFit = `${this.formatDate(new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')))} - ${this.formatDate(new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD')))}`
        }
      },
      immediate: true,
    },
  },
  methods: {
    generate(value) {
      if (value) {
        return `?id=${value}&share=1`
      }
      return ''
    },
    onItemClick(item) {
      console.log(item)
      this.$router.push({ path: '/article-info', query: { id: item.id, share: 1, showType: 'clinical' } })
    },
    onParamChange() {
      let query = {}
      if(this.form.period && this.form.period.length > 0){
        query.startDate =  moment(this.form.period[0]).format('YYYYMMDD')
        query.endDate = moment(this.form.period[1]).format('YYYYMMDD')
      }
      this.$router.push({
        path: '/clinical-research-list',
        query: query,
      })
      console.log(this.form, this.pagination)
      this.pagination.page = 1
      this.getList()
    },
    onParamChange2(val) {
      const [start, end] = val
      this.show = false
      this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.form.period = []
      this.form.period.push(format(start, 'YYYYMMDD'), format(end, 'YYYYMMDD'))
      let query = {}
      if(this.form.period && this.form.period.length > 0){
        query.startDate =  moment(this.form.period[0]).format('YYYYMMDD')
        query.endDate = moment(this.form.period[1]).format('YYYYMMDD')
      }
      this.$router.push({
        path: '/clinical-research-list',
        query: query,
      })
      this.pagination.page = 1
      this.getList()
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getList()
    },
    async getList() {
      console.log(this.form.period, 'this.form.period')
      let query = {
        pageNo: this.pagination.page,
        pageSize: this.pagination.pageSize,
        sourceIds: [9],
        endDate: this.form.period == null ? null : this.form.period[1],
        startDate: this.form.period == null ? null : this.form.period[0],
      }
      let res = await selectArticleList(query)
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      for (const temp of res.results) {
        console.log('sourceId::', temp.sourceid)
      }
      this.list = res.results
      this.pagination.total = res.page.total
      this.pagination.current = this.pagination.page
      this.loading = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
/deep/.ant-anchor-ink::before {
  width: 0px;
}
.item {
  .item-title {
    height: auto;
    > a {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .item-desc {
    height: 3em;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-type {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .item-date {
    font-size: 14px;
  }
}

.mobile {
  .item {
    .item-title {
      height: 2.5em;
      margin-bottom: 20px;
      > a {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .item-desc {
      height: 3.2em;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item-type {
      width: 120px;
    }

    .item-date {
      font-size: 12px;
    }
  }
}
.shaiXuanMore {
  position: absolute;
  right: 15px;
  top: 85px;
}
.drawerContainer {
  padding: 20px 10px;
  .drawerPaixuContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .drawer1 {
    .van-cell {
      padding: 0;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
