import { render, staticRenderFns } from "./NejmEvidenceList.vue?vue&type=template&id=45e6d86f"
import script from "./NejmEvidenceList.vue?vue&type=script&lang=js"
export * from "./NejmEvidenceList.vue?vue&type=script&lang=js"
import style0 from "./NejmEvidenceList.vue?vue&type=style&index=0&id=45e6d86f&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports