<template>
  <div class="">
    <TitleListBox title="专业领域" :list="list" :has-more="false" @more="onMoreClick">
      <template v-slot="{ item }">
        <a
          :href="
            item.code == 80
              ? '/commentary'
              : item.code == 90
              ? 'http://edm.nejmqianyan.cn/2025Tobacco_control/'
              : '/professional-article-list' + generate(item.code)
          "
          @click.prevent="onItemClick(item)"
          class="item"
        >
          <!--          <div class="title-fr">-->
          <!--            <div class="title">-->
          <!--              {{ item.classname }}-->
          <!--            </div>-->
          <!--            <a-button size="small" type="link">-->
          <!--              详情-->
          <!--              <a-icon type="right" />-->
          <!--            </a-button>-->
          <!--          </div>-->
          <!--          <div class="img" :style="{ 'background-image': `url('${item.pic}')` }"></div>-->
          <!--          <img class="img" :src="item.pic" />-->
          <img v-if="item.pic == 1" class="img" src="../../assets/imgs/major10.png" />
          <img v-if="item.pic == 2" class="img" src="../../assets/imgs/major2.png" />
          <img v-if="item.pic == 3" class="img" src="../../assets/imgs/major3.png" />
          <img v-if="item.pic == 4" class="img" src="../../assets/imgs/major4.png" />
          <img v-if="item.pic == 5" class="img" src="../../assets/imgs/major5.png" />
          <img v-if="item.pic == 6" class="img" src="../../assets/imgs/major6.png" />
          <img v-if="item.pic == 7" class="img" src="../../assets/imgs/major7.png" />
          <img v-if="item.pic == 8" class="img" src="../../assets/imgs/major8.png" />
          <img v-if="item.pic == 9" class="img" src="../../assets/imgs/major9.png" />
          <div class="fr">
            <div class="ellipsis desc">
              {{ item.classname }}
            </div>
            <a-button size="small" type="link" class="span">
              详情
              <!-- <a-icon type="right" /> -->
            </a-button>
          </div>
          <div class="fr">
            <div class="ellipsis desc" style="font-size: 16px; color: #666666; font-weight: 400">
              {{ item.enTitle }}
            </div>
          </div>
        </a>
      </template>
    </TitleListBox>
  </div>
</template>

<script>
import TitleListBox from '@/components/index/components/TitleListBox.vue'
import { selectArticleClass } from '@/api/article'
import { PATH_COMMENTARY, PATH_PROFESSIONAL_ARTICLE_LIST, PATH_PROFESSIONAL_MORE } from '@/router/article/paths'
import '../../assets/imgs/major1.png'
import { generateHref } from '@/utils/utils'

export default {
  name: 'ProfessionalField',
  components: { TitleListBox },
  data() {
    return {
      list: [
        {
          code: 90,
          enTitle: 'Tobacco Use Reduction',
          classname: '控烟',
          // enTitle: 'COVID-19',
          // classname: '2019冠状病毒病',
          // pic: "require('../../img/major1.png')",
          pic: 1,
        },
        {
          code: 10,
          enTitle: 'Cardiovascular & Cerebrovascular Diseases',
          classname: '心脑血管疾病',
          pic: 2,
        },
        {
          code: 20,
          enTitle: 'Oncology',
          classname: '肿瘤',
          pic: 3,
        },
        {
          code: 30,
          enTitle: 'Diabetes',
          classname: '糖尿病',
          pic: 4,
        },
        {
          code: 50,
          enTitle: 'Ob/Gyn & Pediatrics',
          classname: '妇产科和儿科',
          pic: 5,
        },
        {
          code: 70,
          enTitle: 'Infectious Disease',
          classname: '传染病',
          pic: 6,
        },
        {
          code: 40,
          enTitle: 'Pulmonary Disease',
          classname: '呼吸系统疾病',
          pic: 7,
        },
        {
          code: 80,
          enTitle: 'Commentary',
          classname: '述评',
          pic: 8,
        },
        {
          code: 60,
          enTitle: 'Other Specialties',
          classname: '其他',
          pic: 9,
        },
      ],
    }
  },
  async created() {
    // await this.selectArticleClass()
  },
  methods: {
    onMoreClick() {
      console.log('专业领域->更多')
      this.$router.push({ path: PATH_PROFESSIONAL_MORE })
    },
    onItemClick(item) {
      console.log(item)
      if (item.code == 80) {
        this.$router.push({ path: PATH_COMMENTARY })
      } else if (item.code == 90) {
        window.open('http://edm.nejmqianyan.cn/2025Tobacco_control/')
      } else {
        this.$router.push({ path: PATH_PROFESSIONAL_ARTICLE_LIST + '?code=' + item.code })
      }
    },
    async selectArticleClass() {
      let res = await selectArticleClass({})
      this.list = res
    },
    generate(item) {
      return generateHref('code', JSON.stringify(item))
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
  }
  .fr {
    margin-bottom: 4px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-top: 10px;
    .desc {
      font-size: 18px;
      cursor: pointer;
      color: #333333;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: 700;
    }
  }
  .img {
    height: 220px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
}
// 移动端样式
.mobile {
  .item {
    .title {
      margin: 0 10px;
    }
    .desc {
      font-size: 16px;
      margin-left: 10px;
    }
    .span {
      font-size: 16px;
      color: #1e4ea6;
    }
  }
}
</style>
