<template>
  <!-- NEJM期刊荟萃 -->
  <MainCenter class="NejmEvidenceList">
    <div class="header">
      <div class="header_main">
        <div :class="$isMobile ? 'txtsFit' : 'txts'">
          <span v-if="!$isMobile">{{ activeKey }}</span>
          <span v-if="$isMobile">
            {{ activeKey }}
            <span>/NEJM期刊荟萃</span>
            <el-button type="primary" @click="drawer = true">筛选</el-button>
          </span>
          <!-- <span v-if="!$isMobile">Cardiovascular & Cerebrovascular</span> -->
        </div>
        <div class="header_main_bottom">
          <el-tabs v-if="!$isMobile" v-model="activeKey" @tab-click="tabChange" type="card">
            <el-tab-pane v-for="item in tabs" :key="item.key" :name="item.tab" :label="item.tab"></el-tab-pane>
          </el-tabs>
          <van-tabs v-else v-model="activeKey" type="card" @click="tabChange">
            <van-tab v-for="item in tabs" :key="item.key" :name="item.tab" :title="item.tab"></van-tab>
          </van-tabs>
          <!-- <el-tabs v-model="activeKey" type="card" @change="tabChange">
            <el-tab-pane
              v-for="item in tabs"
              :key="item.key"
              :name="item.tab"
              :label="item.tab"
            ></el-tab-pane>
          </el-tabs> -->
        </div>
      </div>
    </div>
    <el-drawer :visible.sync="drawer" :with-header="false" size="80%" class="NejmEvidenceListDrawer">
      <el-form :model="form">
        <el-form-item label="排序">
          <SortSelect v-model="form.sortType" :params="params" @change="change" />
        </el-form-item>
        <van-cell title="日期" :value="timeFit" @click="show = true" />
        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="change2" />
        <!--<el-form-item>
          <span slot="label">专业领域</span>
          <ArticleClassCheckbox v-model="form.articleClassIds" @change="change"></ArticleClassCheckbox>
        </el-form-item>-->
      </el-form>
    </el-drawer>
    <div class="main">
      <div v-if="!$isMobile" class="main_l">
        <a-anchor :offsetTop="!$isMobile ? 112 : 62">
          <el-form label-position="top" :model="form">
            <el-form-item label="排序">
              <SortSelect class="paixu" v-model="form.sortType" :params="params" @change="change" />
            </el-form-item>
            <el-form-item label="日期">
              <el-date-picker
                unlink-panels
                style="max-width: 100%"
                v-model="form.date"
                type="daterange"
                format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="请选择日期"
                end-placeholder="请选择日期"
                @change="change"
              ></el-date-picker>
            </el-form-item>
            <!--<el-form-item>
              <span class="label" slot="label">专业领域</span>
              <div style="width: 100%; height: 1px; background-color: #ccc; margin-bottom: 12px"></div>
              <ArticleClassCheckbox v-model="form.articleClassIds" @change="change"></ArticleClassCheckbox>
            </el-form-item>-->
          </el-form>
        </a-anchor>
      </div>
      <div class="main_r">
        <a-list
          :split="false"
          item-layout="vertical"
          size="large"
          :pagination="loading === false ? pagination : null"
          :data-source="listData"
          :loading="loading"
        >
          <div class="item" slot="renderItem" :key="index" slot-scope="item, index">
            <div class="title">
              {{ item.sourceName + '：' }}{{ item.typeName ? item.typeName : item.filterName }}
              <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
            </div>
            <div class="title_s">
              <a :href="'/article-info' + generate(item.permalinks)" @click.prevent="onItemClick(item)">
                {{ item.title }}
              </a>
            </div>
            <div class="title_e">
              <div>
                <RichText :content="item.authorText"  />
              </div>
            </div>
            <a
              :href="'/article-info' + generate(item.permalinks)"
              @click.prevent="onItemClick(item)"
              class="content"
              v-html="item.intro"
              v-if="item.intro !== ''"
            ></a>
            <div class="other">
              <a @click.prevent="onItemClick2" class="other1" v-for="(value, index) in item.classNames" :key="index">
                {{ value }}
              </a>
              <span v-if="item.classNames == null"></span>
              <span v-else>|</span>
              <!-- <span v-if="item.classNames.length != 0">|</span> -->
              {{ item.postdate }}
            </div>
          </div>
        </a-list>
      </div>
    </div>
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import RichText from '@/components/layout/RichText.vue'
import ArticleClassCheckbox from '@/components/select/ArticleClassCheckbox'
import SortSelect from '@/components/form/select/SortSelect.vue'
import { getProfessional } from '@/filters'
import { selectArticleList } from '@/api/article'
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import { generateHref } from '@/utils/utils.js'
import { mapState } from 'vuex'
import { collectArticle, cancelCollect } from '@/api/user-api.js'
import { getSessionUser } from '@/utils/app-utils'
import merge from 'webpack-merge'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'NejmEvidenceList',
  components: { RichText, MainCenter, ArticleClassCheckbox, SortSelect },
  data() {
    return {
      tabs: [
        { key: '综述类', tab: '综述类', id: 23 },
        { key: '病例类', tab: '病例类', id: 24 },
        { key: '论著类', tab: '论著类', id: 25 },
        { key: '评论类', tab: '评论类', id: 26 },
        { key: '其他', tab: '其他', id: 27 },
      ],
      activeKey: '综述类',
      form: {
        date: [],
        articleClassIds: [],
        sortType: '',
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      listData: [],
      pagination: {
        onChange: (page) => {
          console.log(page)
          this.getData(page)
        },
        current: 1,
        pageSize: 20,
        total: 0,
      },
      flag: false,
      loading: true,
      params: [
        { label: '最新在前', value: '0' },
        { label: '最早在前', value: '1' },
        { label: '阅读最多', value: '2' },
      ],
      drawer: false,
      timeFit: '',
      show: false,
      minDate: new Date(2010, 12, 1),
      maxDate: new Date(),
    }
  },
  filters: { getProfessional },
  watch: {
    '$route.query': {
      handler() {
        this.form.articleClassIds = []
        this.activeKey = this.$route.query.type
        if (this.$route.query.sortType) {
          this.form.sortType  = this.$route.query.sortType;
        }
        if (this.$route.query.articleClassIds) {
          for (let i in this.$route.query.articleClassIds.split(',')) {
            this.form.articleClassIds.push(parseInt(this.$route.query.articleClassIds.split(',')[i]))
          }
        }
        if(this.$route.query.startDate&&this.$route.query.endDate){
          this.form.date = [  new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')) ,new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))];
          this.timeFit = `${this.formatDate(this.form.date[0])} - ${this.formatDate(this.form.date[1])}`
        }
        this.change()
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  mounted() {},
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    tabChange() {
      this.form = {
        date: [],
        articleClassIds: [],
        sortType: '',
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      }
      this.timeFit = ''
      this.change()
    },
    change() {
      let query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type.toString()
      }
      if (this.form.sortType) {
        query.sortType = this.form.sortType
      }
      if (this.form.articleClassIds.toString()) {
        query.articleClassIds = this.form.articleClassIds.toString()
      }
      if(this.form.date && this.form.date.length > 0){
        query.startDate =  moment(this.form.date[0]).format('YYYYMMDD')
        query.endDate = moment(this.form.date[1]).format('YYYYMMDD')
      }
      this.$router.push({
        path: '/nejm-evidence-list',
        query: query,
      })
      this.pagination.current = 1
      this.getData(1)
    },
    change2(val) {
      let query = {...this.$route.query};
      const [start, end] = val
      query.startDate = this.form.startDate = moment(start).format('YYYYMMDD')
      query.endDate = this.form.endDate = moment(end).format('YYYYMMDD')
      this.show = false
      this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.pagination.current = 1
      this.$router.push({
        path: '/nejm-evidence-list',
        query: query,
      })
      this.getData2(1)
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    getData(pageNo) {
      this.loading = true
      this.listData = []
      let param = { ...this.form, pageNo, pageSize: this.pagination.pageSize }
      param.sourceIds = [11]
      let ttl = this.tabs.find((v) => v.key === this.activeKey)
        ? this.tabs.find((v) => v.key === this.activeKey).key
        : ''
      //修改原有参数
      this.$router
        .push({
          query: merge(this.$route.query, { type: ttl }),
        })
        .catch((err) => {
          console.log('输出报错', err)
        })
      param.articleFilterIds = this.tabs.find((v) => v.key === this.activeKey)
        ? [this.tabs.find((v) => v.key === this.activeKey).id]
        : []
      param.startDate = param.date && param.date.length ? moment(param.date[0]).format('YYYYMMDD') : null
      param.endDate = param.date && param.date.length ? moment(param.date[1]).format('YYYYMMDD') : null
      delete param.date
      selectArticleList(param).then((res) => {
        if (res.results.length == 0) {
          this.flag = false
        } else {
          this.flag = true
        }
        res.results.forEach((v) => {
          // 切割作者
          v.authorText = this._sliceAuthor(v.author)
          v.postdate = v.postdate ? format(v.postdate, 'YYYY-MM-DD') : null
          if (v.classNames != null) {
            v.classNames = v.classNames.split(',')
            let classNames = []
            v.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            v.classNames = classNames
          }
        })
        this.pagination.current = pageNo
        this.pagination.total = res.page.total
        this.listData = res.results
        this.loading = false
      })
    },
    getData2(pageNo) {
      this.loading = true
      this.listData = []
      let param = { ...this.form, pageNo, pageSize: this.pagination.pageSize }
      param.sourceIds = [11]
      param.articleFilterIds = this.tabs.find((v) => v.key === this.activeKey)
        ? [this.tabs.find((v) => v.key === this.activeKey).id]
        : []
      delete param.date
      selectArticleList(param).then((res) => {
        if (res.results.length == 0) {
          this.flag = false
        } else {
          this.flag = true
        }
        res.results.forEach((v) => {
          // 切割作者
          v.authorText = this._sliceAuthor(v.author)
          v.postdate = v.postdate ? format(v.postdate, 'YYYY-MM-DD') : null
          if (v.classNames != null) {
            v.classNames = v.classNames.split(',')
            let classNames = []
            v.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            v.classNames = classNames
          }
        })
        this.pagination.current = pageNo
        this.pagination.total = res.page.total
        this.listData = res.results
        this.loading = false
      })
    },
    _sliceAuthor(author, limit = 3) {
      author = author.split(',')
      const postList = author.filter((item) => item.charAt(item.length - 1) === '.').map((item) => item.trim())
      let indexArr = []
      let count = 0
      let delLength = 0
      for (let i = 0; i < author.length; i++) {
        // 判断是否是职称
        // 如果是：当前字符串放入indexArr，continue
        // 如果否：说明是作者姓名，判断字符串长度是否超过限制,否：count+1，当前字符串放入indexArr，continue，是：删除上一次添加的名字，中断循环；如果数组长度是否超出limit，否：count+1，当前字符串放入indexArr，continue，是：中断循环
        let index = author[i]
        if (!postList.includes(index.trim())) {
          // 判断字符串长度是否超过限制或数组的长度是否满足limit
          if (indexArr.join(',').length >= 100) {
            indexArr.splice(indexArr.length - 1 - delLength, delLength + 1)
            break
          }
          if (count >= limit) {
            break
          }
          count++
          // console.log(delLength, 'ss')
          delLength = 0
          indexArr.push(index)
        } else {
          delLength++
          indexArr.push(index)
        }
      }
      if (indexArr.length < author.length) {
        return indexArr.join(',') + '...'
      }
      return indexArr.join(',')
    },
    async collect(item) {
      if (this.user) {
        if (item.ifCollect === 0) {
          //收藏
          await collectArticle({ id: item.id })
          this.getData(this.pagination.current)
        } else {
          //取消收藏
          let res = await cancelCollect({ id: item.id })
          if (res) {
            this.getData(this.pagination.current)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>

<style lang="less">
@import '~@/assets/less/variables.less';
.mobile {
  .NejmEvidenceList {
    width: 100%;
    .header {
      height: 128px;
      .header_main_bottom {
        margin-top: 0px !important;
        .ant-tabs-tab {
          width: 121.08px !important;
          height: 24px !important;
          font-size: 12px !important;
          line-height: 22px !important;
        }
        .ant-tabs-tab-active {
          background-color: #e52e00 !important;
          color: #fff !important;
        }
        .van-tabs__wrap {
          height: 40px;
        }
        .van-tabs {
          .van-tab {
            background-color: #ececec;
            border-right: 1px solid #d9d9d9;
            color: #6f6f6f;
            // width: 140px;
          }
        }
        .van-tabs__nav--card {
          box-sizing: border-box;
          height: 30px;
          margin: 0 16px;
          border: none;
          overflow-x: auto;
          .van-tab {
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
            padding: 0 12px;
          }
          .van-tab.van-tab--active {
            color: #fff;
            background-color: #e52e00;
          }
        }
      }
    }
    .main {
      padding: 20px 0;
      .main_r {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        .item {
          margin-bottom: 32px;
          .title {
            font-size: 18px;
            // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
            line-height: 30px;
            color: #e52e00;
          }
          .title_s {
            margin-top: 8px;
            font-size: 18px;
            // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
            color: rgba(0, 0, 0, 0.85);
            line-height: 26px;
          }
          .title_e {
            margin-top: 8px;
            font-size: 14px;
            // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
            color: rgba(0, 0, 0, 0.6);
            line-height: 22px;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
          }
          .content {
            display: inline-block;
            margin-top: 8px;
            font-size: 14px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
            width: 100%;
            // height: 40px;
            // overflow: hidden;
          }
          .other {
            margin-top: 8px;
            font-size: 14px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: rgba(0, 0, 0, 0.6);
            line-height: 20px;
          }
          .other1 {
            margin-top: 8px;
            font-size: 14px;
            // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: rgba(0, 0, 0, 0.6);
            line-height: 20px;
          }
        }
      }
    }
  }
}
.home .NejmEvidenceList {
  width: 100%;
  max-width: 100%;
  width: 100%;
  padding: 0;
}
.NejmEvidenceList {
  .header {
    position: relative;
    // left: calc((1200px - 100vw) / 2);
    // width: 100vw;
    height: 196px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #d9d9d9;
    .header_main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 58px calc((100vw - 1200px) / 2) 0px calc((100vw - 1200px) / 2);
      box-sizing: border-box;
      .ant-tabs-bar {
        margin-bottom: 0;
        border-bottom: none;
      }
      .txts {
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          font-size: 42px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 50px;
        }
        span:nth-child(2) {
          margin-top: 8px;
          font-size: 24px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 32px;
        }
      }
      .txtsFit {
        display: flex;
        flex-direction: column;
        margin-top: -42px;
        padding: 0 15px;
        span:nth-child(1) {
          font-size: 20px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 50px;
          span {
            font-size: 10px;
            margin-top: -20px;
            display: inline-block;
          }
        }
        .el-button {
          float: right;
          height: 32px;
          margin-top: 10px;
        }
      }
      .header_main_bottom {
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-end;
        margin-top: 38.3px;
        .ant-tabs-tab {
          // width: 228px;
          height: 50px;
          font-size: 18px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.85);
          line-height: 50px;
          text-align: center;
          background-color: #ececec;
          border-radius: 2px 2px 0px 0px;
          margin-right: 4px;
        }
        .ant-tabs-tab-active {
          color: #e52e00;
          background-color: #fff;
        }
        .el-tabs__nav {
          border: none;
        }
        .el-tabs__item {
          border: 2px solid #d9d9d9;
          border-bottom: none;
          color: #333;
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background-color: #ececec;
        }
        .el-tabs__item.is-active {
          background-color: #ffffff;
          color: #e52e00;
        }
      }
    }
  }
  .main {
    display: flex;
    padding: 64px 0;
    max-width: 1200px;
    margin: auto;
    .main_l {
      width: 26%;
      min-width: 250px;
      padding-right: 24px;
      box-sizing: border-box;
      .paixu {
        width: 100%;
      }
      .ant-anchor-ink::before {
        width: 0px;
      }
      .el-form-item__label {
        display: block;
      }
      .label {
        display: block;
        width: 100%;
        font-size: 16px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
        box-sizing: border-box;
        padding-bottom: 1px;
      }
      .el-form-item__label {
        font-size: 16px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
        box-sizing: border-box;
        padding-bottom: 8px;
      }
      .ant-anchor-wrapper {
        overflow: hidden;
      }
    }
    .main_r {
      width: 74%;
      border-left: solid 1px @clr-border;
      padding-left: 24px;
      box-sizing: border-box;
      .item {
        margin-bottom: 32px;
        .title {
          font-size: 18px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          line-height: 30px;
          color: #e52e00;
          i {
            float: right;
            cursor: pointer;
            display: none;
          }
        }
        .title_s {
          margin-top: 8px;
          font-size: 18px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          color: rgba(0, 0, 0, 0.85);
          line-height: 26px;
        }
        .title_e {
          margin-top: 8px;
          font-size: 14px;
          // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
          color: rgba(0, 0, 0, 0.6);
          line-height: 22px;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;
        }
        .content {
          display: inline-block;
          margin-top: 8px;
          font-size: 14px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
          width: 100%;
          // height: 40px;
          // overflow: hidden;
        }
        .other {
          margin-top: 8px;
          font-size: 14px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
        }
        .other1 {
          margin-top: 8px;
          font-size: 14px;
          // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .header {
      left: 0;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 16px;
}
.NejmEvidenceListDrawer {
  .el-drawer__body {
    padding: 10px;
  }
  .van-cell {
    padding: 0;
  }
}
</style>
