<template>
  <!-- 文章详情 -->
  <MainCenter id="dingBu" :style="$isMobile ? 'padding-left: 15px; padding-right: 15px;' : ''" >
    <div v-if="data.sourceid != 9 && data.content != ''" :style="$isMobile ? 'margin-top: 15px' : 'margin-top: 45px'">
      <img
        v-if="data.sourceid == 6"
        style="width: 138px"
        class="logo"
        src="../../assets/imgs/logo/nejm_nejm_logo.png"
        alt="logo"
      />
      <img
        v-else-if="data.sourceid == 10"
        style="width: 138px"
        class="logo"
        src="../../assets/imgs/logo/logo_catalyst.png"
        alt="logo"
      />
      <img
        v-else-if="data.sourceid == 11"
        style="width: 138px"
        class="logo"
        src="../../assets/imgs/logo/logo_evidence.png"
        alt="logo"
      />
      <img v-else style="width: 230px" class="logo" src="../../assets/imgs/logo/nejm_nejm_logo-old.png" alt="logo" />
    </div>
    <div class="title">
      <h3 :style="$isMobile ? 'font-size: 20px' : 'font-size: 32px'">
        {{ data ? data.title : '' }}
      </h3>
      <div :style="$isMobile ? 'font-size: 16px;' : 'font-size: 24px'">
        {{ data ? data.outtitle : '' }}
      </div>
      <div v-if="data.author != ''" class="authorInfo">
        <span><RichText :content="data.author"  /></span>
        <a
          v-scroll-to="{
            el: '#authorInfo',
            duration: 0,
            offset: !$isMobile ? -105 : -55,
          }"
        >
          更多
        </a>
      </div>
      <div :class="$isMobile ? 'titleDate-mb' : 'titleDate'">
        <a
          :class="$isMobile ? 'titleDate-mb' : 'titleDate'"
          @click.prevent="onItemClick"
          class="other1"
          v-for="(value, index) in data.classNames"
          :key="index"
        >
          {{ value }}
        </a>
        <span v-if="data.classNames == null"></span>
        <span v-else>|</span>
        {{ data.postdate }}
        <span v-if="data.typeName != null || data.filterName != null">|</span>
        {{ data.typeName ? data.typeName : data.filterName }}
      </div>
    </div>
    <div class="main">
      <div :class="$isMobile ? 'main_top_mb' : 'main_top'">
        <Share
          collect-type="article"
          :isShow="shareIsshow"
          :id="data.id"
          :ifCollect.sync="data.ifCollect"
          :outlink="data.outlink"
          :query="query"
          :appQuery="appQuery"
        ></Share>
      </div>
      <div v-if="$isMobile" class="content_right-mb">
        <template v-if="adList.length > 0">
          <a-list :grid="{ gutter: 10, column: adList.length }" :data-source="adList">
            <a-list-item slot="renderItem" slot-scope="item" style="width: 180px">
              <a :href="'/animation-particulars' + generate(item.permalinks)">
                <a-card class="card-css" @click.prevent="onAnimationClick(item)">
                  <img slot="cover" :src="item.thumb" height="127" />
                  <a-card-meta style="padding: 0px">
                    <div
                      slot="description"
                      style="
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.85);
                        height: 46px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ item!== null ? item.title:'' }}
                    </div>
                  </a-card-meta>
                </a-card>
              </a>
            </a-list-item>
          </a-list>
        </template>
        <CorrelationMobile
          v-if="correlationList != null && correlationList.page.total > 0"
          :correlationList="correlationList"
          :correlation="correlation"
        ></CorrelationMobile>
      </div>
      <div class="main_content">
        <div :class="isShow ? 'content_left' : 'content_left_not'" :style="$isMobile ? 'width:100%' : ''">
          <div class="content_video">
            <BzVideoPlayer
              v-show="data.videoUrl != null"
              :src="data ? assetsUrl + data.videoUrl : ''"
              :poster="data.thumb"
              :relation-id="data.id"
              relation-type="10"
              :subParam="subParam"
              @paramFun="getVideoParam"
            />
          </div>
          <div v-show="ifShow && data.content != '' || usefreeCont" class="shuPingContainer">
            <div class="jiaJianContainer">
              <div
                @click="ifShowShuPing"
                :class="!flag ? 'iconfont icon-shousuoxiajiantou jiaJian' : 'iconfont icon-shousuoshangjiantou jiaJian'"
              >
                <div class="shuPingTag">述评</div>
              </div>
              <div @click="ifShowShuPing" v-if="!flag" class="wantsayTitle">{{ data.wantsayTitle }}</div>
              <!--
                 //第三种场景 ====评述会员模式，如需放开即可
                  <div class="open_vip" v-if="isShow && subscribe_info.service_type == 0 &&!usefreeCont &&subParam.is_subscribe!= 2" @click="openVip()">
                  开通会员权益后阅读
                </div>
              -->
              <div class="open_vip_free"  @click="ifShowShuPing">
                  免费阅读
              </div>
            </div>
            <transition name="slide-fade">
              <div class="wantsayContainer" v-if="flag">
                <RichText :content="flag2 && isShow || usefreeCont? wantSayShow : wantSayHide" id="wenXianSuoJin" />
                <a v-if="!isShow || !flag2" style="color: #2f6bad" @click="showMore">查看更多</a>
                <RichText v-if="flag2 && isShow && data.hascoi == 1 ||usefreeCont" :content="data.coi" style="margin-top: 24.5px" />
              </div>
            </transition>
          </div>

          <RichText
            v-if="!!data.keyword && data.content == '' && !isShow"
            :class="data.keyword != '' ? 'loggedIn3' : ''"
            :content="data.keyword"
            :about="data.aboutcontent"
          />
          <RichText
            v-if="!!data.keyword && !(!!data.keyword && data.content == '' && !isShow) && data.content != ''"
            :class="data.keyword != '' ? 'loggedIn3' : ''"
            :content="data.keyword"
            :about="data.aboutcontent"
          />
          <div
            v-if="
              data.typeid == 2 || data.typeid == 26 || data.typeid == 17 || data.sourceid == 5 || data.sourceid == 6
            "
            style="height: 40px"
          ></div>
          <!--
            isShow && data.content != ''  登录，并且文章内容不为空的时候显示文章

            usefreeCont  或者使用了免费次数后
           -->
          <RichText
            v-if="(isShow && data.content != ''&& subscribe_info.service_type !=0) || usefreeCont || isShow&&subParam.is_subscribe == 2"
            :content="data.content"
            :about="data.aboutcontent"
            class="mainContent"
          />
          <div v-if="isShow && data.content == ''">
            <RichText :content="data.wantsay" :about="data.aboutcontent" class="mainContent" id="wenXianSuoJin" />
            <RichText v-if="data.hascoi == 1" :content="data.coi" style="margin-top: 24.5px" />
          </div>
          <div v-if="!isShow || !islogin" class="ifLoginContainer">
            <div class="login" v-if="!isShow || !islogin">
              <!-- <el-button @click="login" class="ifLogin">登录后阅读全文</el-button> -->
              <nologinPop  v-if="!isShow || !islogin"  @login="login"></nologinPop>
            </div>
          </div>
          <div v-if="isShow" :class="subParam.is_subscribe == 2 || subscribe_info.service_type !='0'|| usefreeCont? '':'ifLoginContainer'">
            <div :class="subParam.is_subscribe == 2 || subscribe_info.service_type !='0'|| usefreeCont?'':'login'" class="novips">
              <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="subParam.is_month_chance =='2'" @noclose="closePop" @getArticle="getArticleInfo" @content="getContent" @showContent="showContent" ></noUseFreeReadPop>
              <noFreeReadPop :subParam="subParam" :islogin="islogin"  ></noFreeReadPop>
            </div>
          </div>
          <div
            id="authorInfo"
            :class="isShow ? 'loggedIn' : ''"
            v-if="(!!data.author || !!data.pcauthor) && showType !== 'clinical'"
          >
            作者信息
          </div>
          <div v-if="(!!data.author || !!data.pcauthor) && showType !== 'clinical'" class="authorAndReference">
            <RichText :content="data.author"  />
            <br />
            <RichText :content="data.pcauthor"  />
          </div>
          
          <div :class="isShow ? 'loggedIn2' : ''" v-if="!!data.aboutcontent">参考文献</div>
          <RichText :content="data.aboutcontent" v-if="!!data.aboutcontent" class="cankao" />
          
          <div :class="isShow ? 'loggedIn2' : ''" v-if="!!data.appendix">附录</div>
          <RichText :content="data.appendix" v-if="!!data.appendix" class=" " />
          
          <div :class="isShow ? 'loggedIn2' : ''" v-if="!!data.thank">答谢</div>
          <RichText :content="data.thank" v-if="!!data.thank" class=" " />
          
          <div id="authorInfo" v-if="showType == 'clinical'" style="margin-top: 33px; color: #4c4c4c">
            <div style="margin-bottom: 6px">作者信息</div>
            <div><RichText :content="data.author"  /></div>
            <div>{{ data.professionalTitle }}</div>
          </div>
        </div>
        <div v-if="!$isMobile" :class="!$isMobile ? 'content_right' : 'content_right-mb'">
          <template v-if="adList.length > 0">
            <a-list :grid="{ gutter: 10, column: adList.length }" :data-source="adList">
              <a-list-item slot="renderItem" slot-scope="item" style="width: 180px">
                <a :href="'/animation-particulars' + generate(item.permalinks)">
                  <a-card class="card-css" @click.prevent="onAnimationClick(item)">
                    <img slot="cover" :src="item.thumb" height="127" />
                    <a-card-meta style="padding: 0px">
                      <div
                        slot="description"
                        style="
                          font-size: 14px;
                          color: rgba(0, 0, 0, 0.85);
                          height: 46px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item!== null ? item.title:'' }}
                      </div>
                    </a-card-meta>
                  </a-card>
                </a>
              </a-list-item>
            </a-list>
          </template>
          <Correlation
            v-if="correlationList != null &&correlationList.page.total > 0"
            :correlationList="correlationList"
            :correlation="correlation"
          ></Correlation>
        </div>
      </div>
    </div>
    <div
      class="iconfont icon-huidaodingbu1 huiDaoDingBu"
      v-scroll-to="{
        el: '#dingBu',
        offset: -1000,
        duration: 0,
      }"
    ></div>

      <FreeReadPop :subParam="subParam" :islogin="islogin" v-show="isShow&&isFree" @close="close" @getArticle="getArticleInfo"   @showContent="showContent" @content="getContent" ></FreeReadPop>
      <!-- 没有免费次数 -->
      <!-- <noFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow&&subscribe_info.service_type == 0" ></noFreeReadPop> -->
      <!-- <noUseFreeReadPop :subParam="subParam" :islogin="islogin" v-if="isShow && isClose && subscribe_info.service_type == 0  && subParam.is_month_chance =='2'" @noclose="closePop" @getArticle="getArticleInfo" @content="getContent" @showContent="showContent" ></noUseFreeReadPop>点击不使用免费次数后，显示可以使用一次免费次数 -->
      <!-- <nologinPops v-if="!isShow" @login="login"></nologinPops> -->
  </MainCenter>
</template>
<script>
window.onpageshow = function(event) {
  if (event.persisted) {
    let url = window.location.href
   window.location.href = url+ '&time='+ Date.parse(new Date());
  }
};
</script>
<script>
import MainCenter from '@/components/layout/MainCenter.vue';
import RichText from '@/components/layout/RichText.vue';
import Share from '@/components/share/Share.vue';
import Correlation from '@/components/layout/Correlation.vue';
import FreeReadPop from '@/components/article/popup/FreeReadPopup.vue';
import noFreeReadPop from '@/components/article/popup/noFreeReadPopup.vue';
// import nologinPops from '@/components/article/popup/nologinPopup.vue';
import nologinPop from '@/components/article/popup/nologinPop.vue';
import noUseFreeReadPop from '@/components/article/popup/noUseFreePopup.vue';
import {
  selectArticleList,
  getArticleDetail,
  getArticleDetailById,
  getRelationVideo,
  getArticleDetail2,
} from '@/api/serach'
import { format } from '@/utils/time-utils.js'
import { mapState } from 'vuex'
import { getSessionUser, setSessionUser } from '@/utils/app-utils'
import { generateHref } from '@/utils/utils'
import CorrelationMobile from '@/components/layout/CorrelationMobile'
import { mapMutations } from 'vuex'
import { loginWithoutPwd,loginWithoutPwdwithQueryload } from '@/api/login'
import { getMemberInfo } from '@/api/personal'
import BzVideoPlayer from '@/components/video/BzVideoPlayer.vue'
import config from 'config'
const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  components: {RichText, BzVideoPlayer, MainCenter, Share, Correlation, CorrelationMobile,FreeReadPop,noFreeReadPop,nologinPop,noUseFreeReadPop},
  name: 'ArticleInfo',
  data() {
    return {
      assetsUrl: config.assetsUrl,
      data: {},
      isFree:false,
      isShowFree:false,
      noFree:false,
      islogin:false,
      usefreeCont:false,///////使用免费次数之后
      subParam:{
        is_subscribe:'',//是否付费文章（1：是；2：否）
        is_have_view_auth:'',//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        is_month_chance:''//未付费或者已付费但是会员到期的用户，每个用户每个月有一次免费的机会 1：表示无机会；2：表示有机会
      },//会员信息
      subscribe_info:{
        service_type:"",
        service_end_date:""
      },//会员信息
      isClose:false,
      correlation: {
        articleIds: [],
        pageNo: 1,
        pageSize: 10,
      },
      correlationList: null,
      shareIsshow: { navigation: false, collect: true, share: true, original: true },
      memberId: null,
      flag: false, //是否显示全部述评
      flag2: false, //是否加载更多
      ifShow: false, //述评是否显示
      showType: null,
      adList: [],
      wantSayHide: null,
      wantSayShow: null,
      query: null,
      appQuery: null,
      isPageHide:true,
    }
  },
  async created() {
    console.log('sg::', this.$route.query.sg)
    console.log('data::', this.$route.query.nejmjmedssodata)
    this.dealOutLink(this.$route.query.sg)
    this.showType = this.$route.query.showType
    console.log(this.showType, 'this.showType')
    this.memberId = Number(window.sessionStorage.getItem('memberId'))
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.href = url+ '&time='+ Date.parse(new Date());
        window.location.reload();
      }
    };
    window.addEventListener('pageshow', function () {
      if (this.isPageHide) {
      window.location.reload();
      }
    });
    window.addEventListener('pagehide', function () {
      this.isPageHide = true;
    });
    if(this.isShow){
      this.islogin = true
      console.log('1')
      this.getUseinfo()
    }else{
      this.islogin = false
    }
    if (this.$route.query.share === '1') {
      this.shareIsshow = { navigation: false, collect: true, share: true, original: false }
    }
    if (this.$route.query.id == null) {
      if (this.$route.query.prev == 'D1Co7866D71DC3Ao9B3B692DoA2o86B4') {
        await getArticleDetail2({ memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
          if (res.classNames != null) {
            res.classNames = res.classNames.split(',')
            let classNames = []
            res.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            res.classNames = classNames
          }
          this.isFree = res.is_subscribe == '1' ? true:false
          this.noFree = res.is_subscribe == '1' ? true:false
          this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
          this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
          this.subParam.is_month_chance = res.is_month_chance
          this.flag2 =  res.is_have_view_auth == '1'?true:false
          this.subParam.articleId = res.id
          this.query = res.query
          this.appQuery = res.appQuery
          res.postdate = format(res.postdate, 'YYYY-MM-DD')
          this.wantSayHide = res.wantsay.substring(0, res.wantsay.indexOf('<hr/>')) //隐藏更多指定内容
          if (res.wantsay.indexOf('<hr/>') == 0) {
            this.wantSayShow = res.wantsay
          } else {
            this.wantSayShow = this.wantSayHide + res.wantsay.substring(res.wantsay.indexOf('<hr/>') + 5) //全部述评内容
          }
          this.data = res
          //&&this.data.haswantsay==1 述评是否显示  1显示 0隐藏
          if (this.data.wantsay != ''&&this.data.haswantsay==1) {
            this.ifShow = true
          } else {
            this.ifShow = false
          }
        })
      } else {
        await getArticleDetail({ memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
          if (res.classNames != null) {
            res.classNames = res.classNames.split(',')
            let classNames = []
            res.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            res.classNames = classNames
          }
          console.log(res,'====res')
          this.isFree = res.is_subscribe == '1' ? true:false
          this.noFree = res.is_subscribe == '1' ? true:false
          this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
          this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
          this.subParam.is_month_chance = res.is_month_chance
          this.flag2 =  res.is_have_view_auth == '1'?true:false
          this.subParam.articleId = res.id
          this.$forceUpdate()
          this.query = res.query
          this.appQuery = res.appQuery
          res.postdate = format(res.postdate, 'YYYY-MM-DD')
          this.wantSayHide = res.wantsay.substring(0, res.wantsay.indexOf('<hr/>')) //隐藏更多指定内容
          if (res.wantsay.indexOf('<hr/>') == 0) {
            this.wantSayShow = res.wantsay
          } else {
            this.wantSayShow = this.wantSayHide + res.wantsay.substring(res.wantsay.indexOf('<hr/>') + 5) //全部述评内容
          }
          this.data = res
          if (this.data.wantsay != ''&&this.data.haswantsay==1) {
            this.ifShow = true
          } else {
            this.ifShow = false
          }
        })
      }
    } else {
      await getArticleDetailById({ id: this.$route.query.id, memberId: this.memberId }).then((res) => {
        if (res.classNames != null) {
          res.classNames = res.classNames.split(',')
          let classNames = []
          res.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          res.classNames = classNames
        }
        this.isFree = res.is_subscribe == '1' ? true:false
        this.noFree = res.is_subscribe == '1' ? true:false
        this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
        this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
        this.subParam.is_month_chance = res.is_month_chance
        this.flag2 =  res.is_have_view_auth == '1'?true:false
        this.subParam.articleId = res.id
        this.$forceUpdate()
        this.query = res.query
        this.appQuery = res.appQuery
        res.postdate = format(res.postdate, 'YYYY-MM-DD')
        if (res.wantsay != null) {
          this.wantSayHide = res.wantsay.substring(0, res.wantsay.indexOf('<hr/>')) //隐藏更多指定内容
          if (res.wantsay.indexOf('<hr/>') == 0) {
            this.wantSayShow = res.wantsay
          } else {
            this.wantSayShow = this.wantSayHide + res.wantsay.substring(res.wantsay.indexOf('<hr/>') + 5) //全部述评内容
          }
        }
        this.data = res
        if (this.data.wantsay != null && this.data.haswantsay==1) {
          this.ifShow = true
        } else {
          this.ifShow = false
        }
        // this.data.content = this.data.content.replace(/<img/g, '<img style="max-width:900px;height:auto;"')
      })
    }
    if (this.data?.relationArticleIds?.length > 0) {
      this.correlation.articleIds = this.data.relationArticleIds
      await selectArticleList(this.correlation).then((res) => {
        if (res) {
          res.results.forEach((element) => {
            element.postdate = format(element.postdate, 'YYYY-MM-DD')
          })
          this.correlationList = res
        }
      })
    } else {
      this.correlationList = {
        page: {
          total: 0,
        },
      }
    }
    let loginUser = getSessionUser()
    let res = await getRelationVideo({
      memberId: null != loginUser ? loginUser.id : null,
      permalinks: this.data.permalinks,
      // permalinks: 'YXQYoa1714678',
    })
    this.adList = res
    this.$nextTick(() => {
      let rich = document.querySelector('#wenXianSuoJin')
      if (rich !== null) {
        let pList = rich.querySelectorAll('div>p')
        pList = Array.prototype.slice.call(pList)
        let pIndex
        pList.filter((item, index) => {
          if (item.innerHTML == '<strong>参考文献</strong>') {
            pIndex = index
          }
        })
        let richList = pList.slice(pIndex + 1)
        richList.forEach((item) => {
          item.classList.add('wenXianSuoJin')
        })
        pList.splice(pIndex + 1)
        richList.forEach((item) => {
          pList.push(item)
        })
      }
      // 给参考文献前求个添加空格
      let canKaoDiv = document.querySelector('.cankao')
      if (canKaoDiv !== null) {
        let canKaoList = canKaoDiv.querySelectorAll('div>div>p')
        canKaoList = Array.prototype.slice.call(canKaoList)
        let canKaoList2 = canKaoList.slice(0, 9)
        canKaoList2.forEach((item) => {
          var cankaolist2text = item.innerText.slice(0, 3)+ '<span style="margin-right: 8px;"></span>' +item.innerText.slice(3);
          item.innerHTML = cankaolist2text;
          item.classList.add('canKaoList2')
        })
        canKaoList.splice(0, 9, ...canKaoList2)
      }
      // js给标题添加类，为了设置font-size:20px
      let strongList = document.querySelector('.mainContent')
      if (strongList !== null) {
        let strongList2 = strongList.querySelectorAll('div>div>p')
        strongList2 = Array.prototype.slice.call(strongList2)
        let strongList3 = strongList2.filter((item) => item.outerHTML.indexOf(`style="text-align: center;"`) !== -1)
        strongList3.forEach((item) => {
          item.classList.add('strongList3')
        })
      }
    })
  },
  watch:{
    isShow: {
        handler(newValue, oldValue) {
            console.log(newValue,oldValue)
            console.log(this.isShow,'isShow')
            if(this.isShow){
                this.islogin = true
                this.getUseinfo()
                setTimeout(()=>{
                    this.getArticleInfo()
                },300)
            }else{
                console.log('退出登录')
                // this.setUser({})
                // setSessionUser({})
                this.islogin = false //退出登录
                this.isClose = false //退出登录，弹窗关闭
                this.memberId = null
                window.sessionStorage.setItem('memberId', null)
                // this.$emit('update:visible', false)
                this.subscribe_info.service_type = 0
                this.getArticleInfo()
                this.$forceUpdate()
            }
        },
        immediate: true
    }
  },
  mounted(){
      if(this.$route.query.nejmjmedssodata){
        console.log(this.$route.query.nejmjmedssodata);
        let vm =this;
        loginWithoutPwdwithQueryload({data:this.$route.query.nejmjmedssodata}).then((res) => {
          console.log(res);
          if(res){
            vm.setUser(res)
            console.log(res,'====用户信息')
            vm.subscribe_info = res.subscribe_info
            vm.subscribe_info.service_type= res.subscribe_info.service_type
            setSessionUser(res)
            window.sessionStorage.setItem('memberId', res.id)
          }
        }).catch((err)=>{
          console.log(err);
          vm.$nextTick(() => {
              vm.$bus.emit('login')
            })
        })
      }
       //是否登录。登录查询用户信息
      if(this.isShow){
        this.initialize()
      }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isShow() {
      return !!this.user
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    getVideoParam(event){
      console.log(event)
      this.isFree = event
    },
    showContent(e){
      this.usefreeCont = e
    },
    getContent(e){
      console.log(e,'文章content')
      this.data.content = e
    },
    async getUseinfo(){
        let res = await getMemberInfo()
        console.log(res,'====用户信息')
        this.subscribe_info = res.subscribe_info
        this.subscribe_info.service_type= res.subscribe_info.service_type
        setSessionUser(res)
        this.memberId = res.id
        window.sessionStorage.setItem('memberId', res.id)
    },
  async  getArticleInfo(){
      if (this.$route.query.id == null) {
        if (this.$route.query.prev == 'D1Co7866D71DC3Ao9B3B692DoA2o86B4') {
          await getArticleDetail2({ memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
            if (res.classNames != null) {
              res.classNames = res.classNames.split(',')
              let classNames = []
              res.classNames.forEach((item) => {
                if (item !== '') {
                  classNames.push(item)
                }
              })
              res.classNames = classNames
            }
            // this.isFree = res.is_subscribe == '1' ? true:false
            this.noFree = res.is_subscribe == '1' ? true:false
            this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
            this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
            this.subParam.is_month_chance = res.is_month_chance
            this.flag2 =  res.is_have_view_auth == '1'?true:false
            this.subParam.articleId = res.id
            this.query = res.query
            this.appQuery = res.appQuery
            res.postdate = format(res.postdate, 'YYYY-MM-DD')
            this.wantSayHide = res.wantsay.substring(0, res.wantsay.indexOf('<hr/>')) //隐藏更多指定内容
            if (res.wantsay.indexOf('<hr/>') == 0) {
              this.wantSayShow = res.wantsay
            } else {
              this.wantSayShow = this.wantSayHide + res.wantsay.substring(res.wantsay.indexOf('<hr/>') + 5) //全部述评内容
            }
            this.data = res
            if (this.data.wantsay != '' && this.data.haswantsay==1) {
              this.ifShow = true
            } else {
              this.ifShow = false
            }
          })
        } else {
          await getArticleDetail({ memberId: this.memberId, permalinks: this.$route.query.permalinks }).then((res) => {
            if (res.classNames != null) {
              res.classNames = res.classNames.split(',')
              let classNames = []
              res.classNames.forEach((item) => {
                if (item !== '') {
                  classNames.push(item)
                }
              })
              res.classNames = classNames
            }
            console.log(res,'====res')
            this.isFree = res.is_subscribe == '1' ? true:false
            this.noFree = res.is_subscribe == '1' ? true:false
            this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
            this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
            this.subParam.is_month_chance = res.is_month_chance
            this.flag2 =  res.is_have_view_auth == '1'?true:false
            this.subParam.articleId = res.id
            this.$forceUpdate()
            this.query = res.query
            this.appQuery = res.appQuery
            res.postdate = format(res.postdate, 'YYYY-MM-DD')
            this.wantSayHide = res.wantsay.substring(0, res.wantsay.indexOf('<hr/>')) //隐藏更多指定内容
            if (res.wantsay.indexOf('<hr/>') == 0) {
              this.wantSayShow = res.wantsay
            } else {
              this.wantSayShow = this.wantSayHide + res.wantsay.substring(res.wantsay.indexOf('<hr/>') + 5) //全部述评内容
            }
            this.data = res
            if (this.data.wantsay != '' && this.data.haswantsay==1) {
              this.ifShow = true
            } else {
              this.ifShow = false
            }
          })
        }
      } else {
        await getArticleDetailById({ id: this.$route.query.id, memberId: this.memberId }).then((res) => {
          if (res.classNames != null) {
            res.classNames = res.classNames.split(',')
            let classNames = []
            res.classNames.forEach((item) => {
              if (item !== '') {
                classNames.push(item)
              }
            })
            res.classNames = classNames
          }
          // console.log(res,'====res')
          this.isFree = res.is_subscribe == '1' ? true:false
          this.noFree = res.is_subscribe == '1' ? true:false
          this.subParam.is_subscribe = res.is_subscribe//是否付费文章（1：是；2：否）
          this.subParam.is_have_view_auth= res.is_have_view_auth,//1：有权限，可以直接看文章详情，即付费购买过服务；2：无权限，没有购买服务
          this.subParam.is_month_chance = res.is_month_chance
          this.flag2 =  res.is_have_view_auth == '1'?true:false
          this.subParam.articleId = res.id
          this.$forceUpdate()
          this.query = res.query
          this.appQuery = res.appQuery
          res.postdate = format(res.postdate, 'YYYY-MM-DD')
          if (res.wantsay != null) {
            this.wantSayHide = res.wantsay.substring(0, res.wantsay.indexOf('<hr/>')) //隐藏更多指定内容
            if (res.wantsay.indexOf('<hr/>') == 0) {
              this.wantSayShow = res.wantsay
            } else {
              this.wantSayShow = this.wantSayHide + res.wantsay.substring(res.wantsay.indexOf('<hr/>') + 5) //全部述评内容
            }
          }
          this.data = res
          if (this.data.wantsay != null && this.data.haswantsay==1) {
            this.ifShow = true
          } else {
            this.ifShow = false
          }
          // this.data.content = this.data.content.replace(/<img/g, '<img style="max-width:900px;height:auto;"')
        })
      }
    },
    async initialize() {
      let res = await getMemberInfo()
      console.log(res,'====用户信息')
      this.subscribe_info = res.subscribe_info || {}
      this.subscribe_info.service_type= res.subscribe_info.service_type || ''
    },
    openVip(){
      this.$router.push({ path:'/personal', query:{ type: 'flag4' } })
        .catch((err) => {
            console.log('输出报错', err)
        })
    },
    close(e){
      console.log(e,'=====e')
      this.flag2 = false
      this.isFree = e.a
      this.isClose = e.b
    },
    //关闭弹窗
    closePop(e){
      console.log(e,'使用次数后')
      this.isClose = e
    },
    async dealOutLink(sg) {
      let loginUser = getSessionUser()
      if (sg != null && sg == 'AbW1N' && loginUser == null) {
        this.setUser({})
        setSessionUser({})
        this.islogin = false //退出登录
        this.isClose = false //退出登录，弹窗关闭
        this.subscribe_info.service_type = 0
        this.usefreeCont = false
        window.sessionStorage.clear()
        await loginWithoutPwd()
      }
    },
    onItemClick(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    //述评开关
    ifShowShuPing() {
      // if(!this.isShow) this.flag = !this.flag
      //目前述评看起来只有2种场景 就是 1 和 2.
      //1. 述评免费 正文免费 --- 这个可以 2. 述评免费 正文收费 -
      // if(this.subscribe_info.service_type==0&&!this.usefreeCont &&this.subParam.is_subscribe!=2) return     //第三种场景，评述收费，如需，放开即可
      this.flag = !this.flag
    },
    login() {
      // this.$emit('login')
      this.$bus.emit('login')
    },
    generate(item) {
      return generateHref('permalinks', item)
    },
    onAnimationClick(item) {
      console.log(item)
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
    showMore() {
      if (!this.isShow) {
        this.$bus.emit('login')
      } else {
        this.flag2 = true
        let rich = document.querySelector('#wenXianSuoJin')
        if (rich !== null) {
          let pList = rich.querySelectorAll('div>p')
          pList = Array.prototype.slice.call(pList)
          let pIndex
          pList.filter((item, index) => {
            if (item.innerHTML == '<strong>参考文献</strong>') {
              pIndex = index
            }
          })
          let richList = pList.slice(pIndex + 1)
          richList.forEach((item) => {
            item.classList.add('wenXianSuoJin')
          })
          pList.splice(pIndex + 1)
          richList.forEach((item) => {
            pList.push(item)
          })
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.novips{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fixed{
  position: fixed;
}
.logo {
  width: 280px;
}
.title {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  h3 {
    width: 100%;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 1.5px;
    //font-family: 'PingFang SC', 'MicrosoftYaHeiSemibold', 'MicrosoftYaHeiSemibold-Regular', 'Microsoft YaHei', tahoma,
    //  arial, 'Hiragino Sans GB', sans-serif;
    color: rgba(0, 0, 0, 0.85);
  }
  h3::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 32px;
    background-color: #e52e00;
    border-radius: 3px;
  }
  div:nth-of-type(1) {
    width: 100%;
    //font-family: 'PingFang SC', 'MicrosoftYaHeiSemibold', 'MicrosoftYaHeiSemibold-Regular', 'Microsoft YaHei', tahoma,
    //  arial, 'Hiragino Sans GB', sans-serif;
    color: rgba(0, 0, 0, 0.85);
    margin: 15px 0 11px 0;
  }
  .authorInfo {
    background-color: #f2f2f2;
    width: 100%;
    padding: 8px 18px;
    margin-bottom: 11px;
    span {
      font-size: 14px;
      line-height: 22px;
    }
    a {
      color: #e52e00;
      float: right;
    }
  }
  .titleDate {
    width: 100%;
    //font-family: 'PingFang SC', 'MicrosoftYaHeiSemibold', 'MicrosoftYaHeiSemibold-Regular', 'Microsoft YaHei', tahoma,
    //  arial, 'Hiragino Sans GB', sans-serif;
    color: #666666;
    font-size: 18px;
    overflow: hidden; /*必须结合的属性,当内容溢出元素框时发生的事情*/
    text-overflow: ellipsis; /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数。*/
    padding: 4px 0;
  }
}
.main {
  width: 100%;
  overflow: hidden;
}
.main_content {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  position: relative;
  .content_left {
    padding-bottom: 50px;
    width: 75%;
    float: left;
    .shuPingContainer {
      border-left: 1px solid #e52e00;
      border-right: 1px solid #e52e00;
      border-bottom: 1px solid #e52e00;
      border-radius: 4px;
      margin-bottom: 20px;
      // transition: height 1s ease;
      .jiaJianContainer {
        padding: 10px 15px;
        background-color: #e52e00;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .jiaJian {
          display: inline-block;
          .shuPingTag {
            display: inline-block;
            margin: 0 5px;
          }
        }
        .wantsayTitle {
          display: inline;
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .open_vip{
          font-size: 20px;
          color: #E52E00;
          text-align: center;
          font-weight: 400;
          background: #FFFFFF;
          border-radius: 50px;
          padding: 11px 35px;
          display: inline-block;
        }
        .open_vip_free{
          font-size: 18px;
          color: #E52E00;
          text-align: center;
          font-weight: 400;
          background: #FFFFFF;
          border-radius: 50px;
          padding: 0 35px;
          display: inline-block;
          position: absolute;
          right: 20px;
        }
      }
      .wantsayContainer {
        padding: 15px;
      }
    }
  }
  .loggedIn {
    margin: 24px 0 8.5px 0;
    font-size: 16px;
    color: #4c4c4c;
  }
  .loggedIn2 {
    margin: 38.5px 0 8.5px 0;
    font-size: 16px;
    color: #4c4c4c;
  }
  .loggedIn3 {
    // margin-bottom: 59px;
  }
  .content_right {
    width: 20%;
    float: right;
  }
  .content_right-mb {
    width: 100%;
    float: left;
  }
}
.content_left_not {
  width: 75%;
  padding-bottom: 50px;
  float: left;
  .shuPingContainer {
    border-left: 1px solid #e52e00;
    border-right: 1px solid #e52e00;
    border-bottom: 1px solid #e52e00;
    border-radius: 4px;
    margin-bottom: 20px;
    transition: height 2s ease;
    .jiaJianContainer {
      padding: 10px 15px;
      background-color: #e52e00;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      .jiaJian {
        display: inline-block;
        .shuPingTag {
          display: inline-block;
          margin: 0 5px;
        }
      }
      .wantsayTitle {
        display: inline;
        width: 469px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .open_vip{
        font-size: 20px;
        color: #E52E00;
        text-align: center;
        font-weight: 400;
        background: #FFFFFF;
        border-radius: 25px;
        padding: 11px 35px;
        display: inline-block;
        margin-left: 20px;
        }
        .open_vip_free{
          font-size: 18px;
          color: #E52E00;
          text-align: center;
          font-weight: 400;
          background: #FFFFFF;
          border-radius: 50px;
          padding: 0 35px;
          display: inline-block;
          position: absolute;
          right: 20px;
        }
    }
    .wantsayContainer {
      padding: 15px;
    }
  }
}
.content_video {
  margin: 20px 0;
  width: 100%;
  min-height: 0;
  video {
    width: 100%;
  }
}
.ifLoginContainer {
  position: relative;
  margin-top: -69px;
  margin-bottom: 200px;
}
.login {
  position: absolute;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  .ifLogin {
    border: 1px solid #e52e00;
    color: #d30000;
    //font-family: 'PingFang SC', 'MicrosoftYaHeiSemibold', 'MicrosoftYaHeiSemibold-Regular', 'Microsoft YaHei', tahoma,
    //  arial, 'Hiragino Sans GB', sans-serif;
    font-size: 14px;
    width: 161px;
    height: 47.6px;
  }
}
.login_mb {
  position: absolute;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  .ifLogin {
    border: 1px solid #e52e00;
    color: #d30000;
    //font-family: 'PingFang SC', 'MicrosoftYaHeiSemibold', 'MicrosoftYaHeiSemibold-Regular', 'Microsoft YaHei', tahoma,
    //  arial, 'Hiragino Sans GB', sans-serif;
    font-size: 14px;
    width: 161px;
    height: 47.6px;
  }
}
.main_top_mb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 18%;
}

//++++++++++++mobile+++++++++++++++++++++++
.mobile {
  .title {
    h3 {
      line-height: 28px;
    }
    div:nth-of-type(1) {
      line-height: 20px;
      margin: 10px 0 6px 0;
    }
    .titleDate-mb {
      color: #666666;
      font-size: 14px;
      padding: 4px 0;
    }
  }
  .title h3::before {
    width: 4px;
    height: 20px;
  }
  .main {
    .main_content {
      .content_left {
        .shuPingContainer {
          display: block;
          .jiaJianContainer {
            font-size: 16px;
            position: relative;
            .jiaJian {
              font-size: 16px;
            }
          }
          .wantsayContainer {
            div {
              font-size: 16px;
            }
          }
        }
        .open_vip{
              font-size: 14px;
              color: #e52e00;
              font-weight: 400;
              background: #fff;
              border-radius: 50px;
              padding: 0px 10px;
            }
        .open_vip_free{
          font-size: 18px;
          color: #E52E00;
          text-align: center;
          font-weight: 400;
          background: #FFFFFF;
          border-radius: 50px;
          padding: 0 35px;
          display: inline-block;
          position: absolute;
          right: 20px;
        }
      }
      .content_left_not {
        .shuPingContainer {
          .jiaJianContainer {
            font-size: 16px;
            flex-direction: column;
            align-items: flex-start;
            .jiaJian {
              font-size: 16px;
            }
            .wantsayTitle{
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

          }
          .wantsayContainer {
            div {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .huiDaoDingBu {
    right: 10px;
    bottom: 10px;
    font-size: 40px;
  }
}
.authorAndReference {
  font-size: 10px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.huiDaoDingBu {
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: #e52e00;
  font-size: 45px;
  cursor: pointer;
}
</style>
<style lang="less">
.wenXianSuoJin {
  padding-left: 16px;
  text-indent: -16px;
  // padding-left: 2em;
  // text-indent: -2em;
}
.cankao {
  .rich-text {
    font-size: 10px;
    p > span {
      font-size: 10px;
    }
    p {
      padding-left: 21px;
      text-indent: -21px;
      text-align: left;
      word-wrap: break-word;
    }
    .canKaoList2 {
      // padding-left: 1em;
      // text-indent: -1em;
      padding-left: 21px;
      text-indent: -21px;
    }
  }
  .canKaoList2 {
    // margin-left: 5px;
  }
}
.loggedIn3 {
  .rich-text {
    font-size: 16px;
    .font-size-title {
      font-size: 1.5rem;
      font-size: 20px !important;
      strong {
        font-size: 20px !important;
      }
    }
  }
}
.mainContent {
  .rich-text {
    font-size: 16px;
    .font-size-title {
      font-size: 20px !important;
      strong {
        font-size: 20px !important;
      }
      span {
        font-size: 20px !important;
      }
    }
    .strongList3 strong {
      font-size: 20px !important;
    }
    .strongList3 span {
      font-size: 20px !important;
    }
  }
}
.font-size-content strong span {
  font-size: 1rem;
}
.title-ad {
  margin: 12px 0;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 10px;
}
.card-css {
  cursor: pointer;
  border: none;
}
.card-css :nth-child(2) {
  zoom: 1;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
</style>
