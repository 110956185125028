<template>
  <!-- 文章 -->
  <div class="ArticleList" :style="$isMobile ? 'padding: 24px 0;' : ''">
    <div v-if="!$isMobile" class="main_l">
      <a-anchor :offsetTop="!$isMobile ? 112 : 62" style="width: 288px">
        <el-form label-position="top" :model="form">
          <el-form-item label="排序" prop="sortType">
            <SortSelect class="paixu" v-model="form.sortType" :params="param" @change="change" />
          </el-form-item>
          <el-form-item v-for="(item, index) in allOptions" :key="index">
            <span class="label" slot="label" v-if="index !== allOptions.length - 1">{{ item.label }}</span>
            <div style="width: 100%; height: 1px; background-color: #ccc; margin-bottom: 8px" v-if="index !== allOptions.length - 1"></div>
            <!-- <ArticleTypeCheckbox
              v-if="index === allOptions.length - 1"
              :params="item.params"
              v-model="form[item.key]"
              @change="change"
            ></ArticleTypeCheckbox> -->
            <ArticleFilterCheckbox
              v-if="index !== allOptions.length - 1"
              :params="item.params"
              v-model="form[item.key]"
              @change="change"
            ></ArticleFilterCheckbox>
          </el-form-item>
        </el-form>
      </a-anchor>
    </div>
    <el-button v-if="$isMobile" type="primary" @click="drawer = true" class="ArticleListShaiXuan">筛选</el-button>
    <el-drawer :visible.sync="drawer" :with-header="false" size="80%" class="ArticleListDrawer">
      <el-form label-position="top" :model="form" class="clearLunWen">
        <el-form-item label="排序" prop="sortType">
          <SortSelect style="width: 286px" v-model="form.sortType" :params="param" @change="change" />
        </el-form-item>
        <el-form-item v-for="(item, index) in allOptions" :key="index">
          <span class="label" slot="label" v-if="index !== allOptions.length - 1">{{ item.label }}</span>
          <div style="width: 100%; height: 1px; background-color: #ccc; margin-bottom: 8px" v-if="index !== allOptions.length - 1"></div>
          <!-- <ArticleTypeCheckbox
            v-if="index === allOptions.length - 1"
            :params="item.params"
            v-model="form[item.key]"
            @change="change"
          ></ArticleTypeCheckbox> -->
          <ArticleFilterCheckbox
            v-if="index !== allOptions.length - 1"
            :params="item.params"
            v-model="form[item.key]"
            @change="change"
          ></ArticleFilterCheckbox>
        </el-form-item>
      </el-form>
    </el-drawer>
    <div :class="$isMobile ? 'main_r_mb' : 'main_r'">
      <!--      <MobileFilterSelect v-if="$isMobile"></MobileFilterSelect>-->
      <a-list
        :split="false"
        size="large"
        :pagination="loading === false ? pagination : null"
        :loading="loading"
        :data-source="listData"
      >
        <div class="item" slot="renderItem" :key="index" slot-scope="item, index">
          <div class="title">
            {{ item.sourceName + '：' }}{{ item.typeName ? item.typeName : item.filterName }}
            <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
          </div>
          <div class="title_s">
            <a :href="'/article-info' + generate(item.permalinks)" @click.prevent="onItemClick(item)">
              {{ item.title }}
            </a>
          </div>
          <div class="title_e">
            <div>
              <RichText :content="item.authorText"  />
            </div>
          </div>
          <a
            :href="'/article-info' + generate(item.permalinks)"
            @click.prevent="onItemClick(item)"
            class="content"
            v-html="item.intro"
            v-if="item.intro !== ''"
          ></a>
          <div class="other">
            <a @click.prevent="onItemClick2" class="other1" v-for="(value, index) in item.classNames" :key="index">
              {{ value }}
            </a>
            <span v-if="item.classNames == null"></span>
            <span v-else style="margin-left: 4px">|</span>
            <!-- <span v-if="item.classNames.length != 0" style="margin-left: 4px">|</span> -->
            {{ item.postdate }}
          </div>
        </div>
      </a-list>
    </div>
  </div>
</template>

<script>
import ArticleFilterCheckbox from '@/components/select/ArticleFilterCheckbox'
import RichText from '@/components/layout/RichText.vue'
// import ArticleTypeCheckbox from '@/components/select/ArticleTypeCheckbox'
import SortSelect from '@/components/form/select/SortSelect'
import moment from 'moment'
import { generateHref } from '@/utils/utils.js'
import { mapState } from 'vuex'
import { collectArticle, cancelCollect } from '@/api/user-api.js'

const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}

export default {
  name: 'ArticleList',
  props: ['params', 'pagination', 'listData', 'loading'],
  components: { RichText, ArticleFilterCheckbox, SortSelect },
  data() {
    return {
      code: null,
      allOptions: [
        {
          label: 'NEJM',
          params: { id: 5 },
          key: 'articleFilterIds',
        },
        // {
        //   label: 'NEJM期刊荟萃',
        //   params: { id: 6 },
        //   key: 'articleFilterIds',
        // },
        {
          label: '临床研究论文撰写',
          params: { sourceid: 9 },
          key: 'articleTypeIds',
        },
      ],
      form: {
        sortType: '',
        articleFilterIds: [],
        articleTypeIds: [],
      },
      param: [
        { label: '最新在前', value: '0' },
        { label: '最早在前', value: '1' },
        { label: '阅读最多', value: '2' },
      ],
      drawer: false,
    }
  },
  mounted() {
    this.form = { ...this.form, ...this.params }
    this.handler()
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    '$route.query': {
      handler() {
        this.form.articleTypeIds = []
        this.form.articleFilterIds = []
        this.form.sortType = ''
        if (this.$route.query.code) {
          this.code = this.$route.query.code
        }
        if (this.$route.query.sortType) {
          this.form.sortType = this.$route.query.sortType
        }
        if (this.$route.query.articleTypeIds) {
          this.form.articleTypeIds.push(parseInt(this.$route.query.articleTypeIds))
        }
        if (this.$route.query.articleFilterIds) {
          for (let i in this.$route.query.articleFilterIds.split(',')) {
            this.form.articleFilterIds.push(parseInt(this.$route.query.articleFilterIds.split(',')[i]))
          }
        }
        if(this.$route.query.startDate&&this.$route.query.endDate){
          this.$set(this.form,'date',[new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')),new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))]);
          // this.timeFit = `${this.formatDate(this.form.date[0])} - ${this.formatDate(this.form.date[1])}`
          console.log(this.form,222222222222);
        }else {
          this.$set(this.form,'date',null)
        }
        this.change1()
      },
      immediate: true,
    },
  },
  methods: {
    onItemClick(item) {
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
    handler() {
      this.form.articleTypeIds = []
      this.form.articleFilterIds = []
      this.sortType = ''
      if (this.$route.query.code) {
        this.code = this.$route.query.code
      }
      if (this.$route.query.sortType) {
        this.form.sortType = this.$route.query.sortType
      }
      if (this.$route.query.articleTypeIds) {
        this.form.articleTypeIds.push(parseInt(this.$route.query.articleTypeIds))
      }
      if (this.$route.query.articleFilterIds) {
        for (let i in this.$route.query.articleFilterIds.split(',')) {
          this.form.articleFilterIds.push(parseInt(this.$route.query.articleFilterIds.split(',')[i]))
        }
      }
    },
    change1() {
      console.log(this.form);
      this.$emit('search', this.form)
    },
    change() {
      let query = {};
      query.code = this.code
      if (this.form.sortType) {
        query.sortType = this.form.sortType
      }
      if (this.form.articleFilterIds.toString()) {
        query.articleFilterIds = this.form.articleFilterIds.toString()
      }
      if (this.form.articleTypeIds.toString()) {
        query.articleTypeIds = this.form.articleTypeIds.toString()
      }
      if(this.$route.query.startDate && this.$route.query.endDate){
        query.startDate =  this.$route.query.startDate
        query.endDate = this.$route.query.endDate
        this.$set(this.form,'date',[new Date(moment(this.$route.query.startDate).format('YYYY-MM-DD')),new Date(moment(this.$route.query.endDate).format('YYYY-MM-DD'))]);
      }
      this.$router.push({
        path: '/professional-article-list',
        query: query,
      })
      this.$emit('search', this.form)
    },
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    async collect(item) {
      if (this.user) {
        if (item.ifCollect === 0) {
          //收藏
          await collectArticle({ id: item.id })
          this.$bus.emit('refresh-page')
        } else {
          //取消收藏
          let res = await cancelCollect({ id: item.id })
          if (res) {
            this.$bus.emit('refresh-page')
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>
<style lang="less">
@import '~@/assets/less/variables.less';
.clearLunWen {
  .el-form-item:nth-last-of-type(1) {
    display: none; //去掉临床研究论文
  }
}
.ArticleList {
  display: flex;
  padding: 64px 0;
  // width: 1200px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  .main_l {
    // width: 26%;
    padding-right: 24px;
    box-sizing: border-box;
    .paixu {
      width: 100%;
    }
    .ant-anchor-ink::before {
      width: 0px;
    }
    .el-form-item__label {
      display: block;
    }
    .label {
      display: block;
      width: 100%;
      font-size: 16px;
      // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
      color: rgba(0, 0, 0, 0.85);
      line-height: 26px;
      box-sizing: border-box;
      // padding-bottom: 6px;
    }
  }
  .main_r {
    width: 74%;
    border-left: solid 1px @clr-border;
    padding-left: 24px;
    box-sizing: border-box;
    .item {
      margin-bottom: 32px;
      .title {
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        line-height: 30px;
        color: #e52e00;
        i {
          float: right;
          cursor: pointer;
          display: none;
        }
      }
      .title_s {
        margin-top: 8px;
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
      }
      .title_e {
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
      .content {
        display: inline-block;
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        width: 100%;
        // height: 40px;
        // overflow: hidden;
      }
      .other {
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
        i {
          float: right;
          cursor: pointer;
        }
      }
      .other1 {
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
    }
  }
  .main_r_mb {
    width: 100%;
    border-left: solid 1px @clr-border;
    padding: 0 15px;
    box-sizing: border-box;
    .item {
      margin-bottom: 32px;
      .title {
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        line-height: 26px;
        color: #e52e00;
        i {
          float: right;
          cursor: pointer;
          display: none;
        }
      }
      .title_s {
        margin-top: 8px;
        font-size: 16px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 18px;
      }
      .title_e {
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 16px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
      .content {
        display: inline-block;
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        width: 100%;
        // height: 40px;
        // overflow: hidden;
      }
      .other {
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
      .other1 {
        margin-top: 8px;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
    }
  }
}
.ant-list-pagination {
  margin-top: 56px;
  text-align: center;
}
.content:hover,
.other1:hover {
  color: #e52e00 !important;
}
.el-form--label-top .el-form-item__label {
  padding: 0 0 8px 0;
}
.el-checkbox-group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.el-checkbox {
  width: 50%;
  margin-right: 0px;
}
// .el-checkbox:nth-child(2n + 1) {
//   width: 50%;
// }
// .el-checkbox:nth-child(2n) {
//   width: 50%;
// }
.ArticleListShaiXuan {
  position: absolute;
  right: 10px;
  z-index: 1;
}
.ArticleListDrawer {
  .el-drawer__body {
    padding: 10px;
  }
}
</style>
