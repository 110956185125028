<template>
  <MainCenter>
    <div :class="$isMobile ? 'moreFit' : 'more'">更多相关阅读</div>
    <!-- <div :class="$isMobile ? 'eMoreFit' : 'eMore'">Cardiovascular & Cerebrovascular</div> -->
    <el-button v-if="$isMobile" @click="drawer = true" class="shaiXuanMore" type="primary">筛选</el-button>

    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <div class="drawerContainer">
        <div class="drawer1">
          <van-cell title="日期" :value="timeFit" @click="show = true" />
          <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" type="range" @confirm="changeTime" />
        </div>
        <div class="drawer2">
          <div class="drawerTitle">NEJM</div>
          <div style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 8px 0"></div>
          <el-checkbox-group v-model="params.articleFilterIds" @change="handleCheckedTypesChange">
            <el-checkbox v-for="type in types1" :label="type.key" :key="type.key">
              {{ type.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="drawer3">
          <div class="drawerTitle">NEJM期刊荟萃</div>
          <div style="width: 100%; height: 1px; background-color: #d4d4d4; margin: 8px 0"></div>
          <el-checkbox-group v-model="params.articleFilterIds" @change="handleCheckedTypesChange">
            <el-checkbox v-for="type in types2" :label="type.key" :key="type.key">
              {{ type.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </el-drawer>

    <div v-if="!$isMobile" style="width: 100%; height: 1px; background-color: #ccc; margin-bottom: 64px"></div>
    <div v-else style="padding: 0 15px">
      <div style="width: 100%; height: 1px; background-color: #ccc"></div>
    </div>
    <div :class="$isMobile ? '' : 'fenlan'">
      <div v-if="!$isMobile" class="left">
        <a-anchor :offsetTop="!$isMobile ? 112 : 62">
          <div class="date">
            <div class="riqi">日期</div>
            <el-date-picker
              unlink-panels
              v-model="time"
              @change="changeTime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 268px"
            ></el-date-picker>
          </div>
          <div class="module">
            <div class="title">NEJM</div>
            <div style="width: 268px; height: 1px; background-color: #d4d4d4; margin-bottom: 8px"></div>
            <el-checkbox-group v-model="params.articleFilterIds" @change="handleCheckedTypesChange">
              <el-checkbox v-for="type in types1" :label="type.key" :key="type.key">
                {{ type.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="module">
            <div class="title">NEJM期刊荟萃</div>
            <div style="width: 268px; height: 1px; background-color: #d4d4d4; margin-bottom: 8px"></div>
            <el-checkbox-group v-model="params.articleFilterIds" @change="handleCheckedTypesChange">
              <el-checkbox v-for="type in types2" :label="type.key" :key="type.key">
                {{ type.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </a-anchor>
      </div>
      <div v-if="!$isMobile" style="width: 1px; background-color: #d4d4d4"></div>
      <div class="right">
        <a-list
          :split="false"
          class="list"
          item-layout="vertical"
          size="large"
          :pagination="flag === true ? pagination : null"
          :data-source="listData"
          :loading="loading"
        >
          <a-list-item slot="renderItem" :key="index" slot-scope="item, index">
            <div v-if="item.sourceName !== '视频'" class="contentType">
              {{ item.sourceName + '：' }}{{ item.typeName ? item.typeName : item.filterName }}
              <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
            </div>
            <div v-if="item.sourceName === '视频'" class="contentType">
              {{ item.typeName ? item.typeName : item.filterName }}
              <i v-if="item.ifCollect != 0" class="iconfont icon-shanchu" @click="collect(item)"></i>
            </div>
            <a
              :href="'/animation-particulars' + generate(item.permalinks)"
              v-if="item.sourceName === '视频'"
              style="display: block; margin-bottom: 8px"
              @click.prevent="onItemClick3(item)"
            >
              <el-image style="width: 227px; height: 127px" :src="item.thumb" fit="cover"></el-image>
            </a>
            <div class="contentTitle">
              <a
                :href="'/animation-particulars' + generate(item.permalinks)"
                v-if="item.sourceName === '视频'"
                @click.prevent="onItemClick3(item)"
              >
                {{ item.title }}
              </a>
              <a
                :href="'/article-info' + generate(item.permalinks)"
                v-if="item.sourceName !== '视频'"
                @click.prevent="onItemClick(item)"
              >
                {{ item.title }}
              </a>
            </div>
            <div :class="item.sourceName !== '视频' ? 'contentAuthor' : null">
              <div>
                <RichText :content="item.authorText"  />
              </div>
            </div>
            <a
              :href="'/article-info' + generate(item.permalinks)"
              @click.prevent="onItemClick(item)"
              v-if="item.sourceName !== '视频' && item.intro !== ''"
              v-html="item.intro"
              class="content"
            ></a>
            <div class="contentOther">
              <a
                @click.prevent="onItemClick2"
                class="contentOther1"
                v-for="(value, index) in item.classNames"
                :key="index"
              >
                {{ value }}
              </a>

              <span v-if="item.classNames == null"></span>
              <span v-else>|</span>
              <!-- <span v-if="item.classNames.length != 0">|</span> -->
              {{ item.postdate }}
            </div>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </MainCenter>
</template>
<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import RichText from '@/components/layout/RichText.vue'
import { selectArticleList } from '@/api/article'
import moment from 'moment'
import { format } from '@/utils/time-utils.js'
import { generateHref } from '@/utils/utils.js'
import { mapState } from 'vuex'
import { collectArticle, cancelCollect } from '@/api/user-api.js'
import { getSessionUser } from '@/utils/app-utils'

const idOf1 = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }
const idOf2 = { 6: 6, 7: 7, 8: 8, 15: 15, 16: 16 }
const types = {
  心脑血管疾病: '10',
  肿瘤: '20',
  糖尿病: '30',
  呼吸系统疾病: '40',
  妇产科和儿科: '50',
  围产医学和儿科: '50',
  其他: '60',
  传染病: '70',
}
export default {
  name: 'ProfessionalMore',
  components: { RichText, MainCenter },
  data() {
    return {
      params: {
        articleIds: null,
        articleFilterIds: [],
        sourceIds: null,
        startDate: null,
        endDate: null,
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      },
      time: [],
      timeFit: '',
      types1: [
        { key: 1, label: '综述类文章' },
        { key: 2, label: '临床病例类文章' },
        { key: 3, label: '论著类文章' },
        { key: 4, label: '评论类文章' },
        { key: 5, label: '其他' },
      ],
      types2: [
        { key: 6, label: '对临床实践的启示' },
        { key: 7, label: '临床实践的改变' },
        { key: 8, label: '新兴领域' },
        { key: 15, label: '临床指南' },
        { key: 16, label: '其他' },
      ],
      pagination: {
        onChange: (page) => {
          this.getSelectArticleList2(page)
        },
        current: 1,
        pageSize: 20,
        total: 0,
      },
      // NEJM及NEJM期刊荟萃
      listData: [],
      flag: false,
      drawer: false,
      show: false,
      minDate: new Date(1970, 1, 1),
      maxDate: new Date(2050, 12, 31),
      loading: true,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.getSelectArticleList()
  },
  methods: {
    generate(item) {
      return generateHref('permalinks', item)
    },
    onItemClick(item) {
      this.$router.push({ path: '/article-info', query: { permalinks: item.permalinks } })
    },
    onItemClick2(item) {
      this.$router.push({ path: '/professional-article-list', query: { code: types[item.target.innerText.trim()] } })
    },
    onItemClick3(item) {
      this.$router.push({ path: '/animation-particulars', query: { permalinks: item.permalinks } })
    },
    // 初始化请求
    async getSelectArticleList() {
      if (this.$route.query.articleIds !== undefined) {
        let articleIds = this.$route.query.articleIds.map((item) => {
          return (item = Number(item))
        })
        this.params.articleIds = articleIds
      }
      let res = await selectArticleList({
        pageNo: 1,
        pageSize: this.pagination.pageSize,
        articleIds: this.params.articleIds,
        memberId: null != getSessionUser() ? getSessionUser().id : null,
      })
      if (res.results.length == 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      res.results.map((itme) => {
        // 切割作者
        itme.authorText = this._sliceAuthor(itme.author)
        itme.postdate = format(itme.postdate, 'YYYY-MM-DD')
        if (itme.classNames != null) {
          itme.classNames = itme.classNames.split(',')
          let classNames = []
          itme.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          itme.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.listData = data
      this.loading = false
    },
    // 分页请求
    async getSelectArticleList2(pageNo) {
      let param = { pageNo, pageSize: this.pagination.pageSize, ...this.params }
      let res = await selectArticleList(param)
      res.results.map((itme) => {
        // 切割作者
        itme.authorText = this._sliceAuthor(itme.author)
        if (itme.classNames != null) {
          itme.postdate = format(itme.postdate, 'YYYY-MM-DD')
          itme.classNames = itme.classNames.split(',')
          let classNames = []
          itme.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          itme.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = pageNo
      this.listData = data
    },
    // 选择时间或类型请求
    async getSelectArticleList3() {
      let param = { pageNo: 1, pageSize: this.pagination.pageSize, ...this.params }
      let res = await selectArticleList(param)
      res.results.map((itme) => {
        // 切割作者
        itme.authorText = this._sliceAuthor(itme.author)
        if (itme.classNames != null) {
          itme.postdate = format(itme.postdate, 'YYYY-MM-DD')
          itme.classNames = itme.classNames.split(',')
          let classNames = []
          itme.classNames.forEach((item) => {
            if (item !== '') {
              classNames.push(item)
            }
          })
          itme.classNames = classNames
        }
      })
      let data = res.results
      this.pagination.total = res.page.total
      this.pagination.current = param.pageNo
      this.listData = data
    },
    changeTime(val) {
      console.log(val)
      if (val !== null) {
        this.params.startDate = moment(val[0]).format('YYYYMMDD')
        this.params.endDate = moment(val[1]).format('YYYYMMDD')
        this.getSelectArticleList3()
      } else {
        this.params.startDate = null
        this.params.endDate = null
        this.getSelectArticleList3()
      }
      if (this.$isMobile) {
        const [start, end] = val
        this.show = false
        this.timeFit = `${this.formatDate(start)} - ${this.formatDate(end)}`
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    handleCheckedTypesChange(val) {
      this.params.sourceIds = []
      val.map((item) => {
        if (item === idOf1[item] && this.params.sourceIds.indexOf(5) == -1) {
          this.params.sourceIds.push(5)
        }
        if (item === idOf2[item] && this.params.sourceIds.indexOf(6) == -1) {
          this.params.sourceIds.push(6)
        }
      })
      this.getSelectArticleList3()
    },
    _sliceAuthor(author, limit = 3) {
      author = author.split(',')
      const postList = author.filter((item) => item.charAt(item.length - 1) === '.').map((item) => item.trim())
      let indexArr = []
      let count = 0
      let delLength = 0
      for (let i = 0; i < author.length; i++) {
        // 判断是否是职称
        // 如果是：当前字符串放入indexArr，continue
        // 如果否：说明是作者姓名，判断字符串长度是否超过限制,否：count+1，当前字符串放入indexArr，continue，是：删除上一次添加的名字，中断循环；如果数组长度是否超出limit，否：count+1，当前字符串放入indexArr，continue，是：中断循环
        let index = author[i]
        if (!postList.includes(index.trim())) {
          // 判断字符串长度是否超过限制或数组的长度是否满足limit
          if (indexArr.join(',').length >= 100) {
            indexArr.splice(indexArr.length - 1 - delLength, delLength + 1)
            break
          }
          if (count >= limit) {
            break
          }
          count++
          // console.log(delLength, 'ss')
          delLength = 0
          indexArr.push(index)
        } else {
          delLength++
          indexArr.push(index)
        }
      }
      if (indexArr.length < author.length) {
        return indexArr.join(',') + '...'
      }
      return indexArr.join(',')
    },
    async collect(item) {
      if (this.user) {
        if (item.ifCollect === 0) {
          //收藏
          await collectArticle({ id: item.id })
          this.getSelectArticleList2(this.pagination.current)
        } else {
          //取消收藏
          let res = cancelCollect({ id: item.id })
          if (res) {
            this.getSelectArticleList2(this.pagination.current)
          }
        }
      } else {
        this.$bus.emit('login')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.drawerContainer {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  .drawer1 {
    .van-cell {
      padding: 0;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .drawer2 {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    .el-checkbox-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .el-checkbox {
        width: 100%;
      }
    }
  }
  .drawer3 {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    .el-checkbox-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .el-checkbox {
        width: 100%;
      }
    }
  }
}
.more {
  margin-top: 56px;
  font-size: 32px;
  // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
  color: rgba(0, 0, 0, 0.85);
  line-height: 40px;
  margin-bottom: 8px;
}
.moreFit {
  margin-top: 20px;
  font-size: 20px;
  // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
  padding: 0 15px;
}
.moreFit::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #e52e00;
  margin-right: 5px;
}
.eMore {
  font-size: 18px;
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.85);
  line-height: 26px;
  margin-bottom: 16px;
}
.eMoreFit {
  // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
  padding: 0 15px;
}
.more::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 30px;
  background-color: #e52e00;
  margin-right: 11px;
}
.fenlan {
  display: flex;
  justify-content: space-between;

  .left {
    width: 23%;
    /deep/.ant-anchor-wrapper {
      overflow: visible;
    }
    /deep/.ant-anchor-ink::before {
      width: 0px;
    }
    .date {
      margin-bottom: 16px;
      .riqi {
        font-size: 16px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
    .module {
      width: 340px;
      margin-bottom: 8px;
      .title {
        font-size: 16px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
  }
  .right {
    width: 73%;
    .list {
      margin-bottom: 64px;
      .contentType {
        // display: inline-block;
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        line-height: 30px;
        color: #e52e00;
        margin-bottom: 8px;
        i {
          float: right;
          cursor: pointer;
        }
      }
      .contentTitle {
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        margin-bottom: 8px;
      }
      .contentAuthor {
        font-size: 14px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
        margin-bottom: 8px;
      }
      .content {
        display: inline-block;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        margin-bottom: 8px;
        width: 100%;
        height: 40px;
        overflow: hidden;
      }
      .contentOther {
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
      .contentOther1 {
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
    }
  }
}
.mobile {
  .right {
    padding: 0 15px;
    /deep/.ant-list-pagination {
      margin: 20px 0 40px 0px;
    }
    .list {
      .contentType {
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        line-height: 30px;
        color: #e52e00;
        margin-bottom: 8px;
        i {
          float: right;
          cursor: pointer;
        }
      }
      .contentTitle {
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
        font-size: 18px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        margin-bottom: 8px;
      }
      .contentAuthor {
        font-size: 14px;
        // font-family: MicrosoftYaHeiSemibold, MicrosoftYaHeiSemibold-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
        margin-bottom: 8px;
      }
      .content {
        display: inline-block;
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        margin-bottom: 8px;
        width: 100%;
        height: 40px;
        overflow: hidden;
      }
      .contentOther {
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
      .contentOther1 {
        font-size: 14px;
        // font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
    }
  }
}
.el-checkbox-group {
  width: 268px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.el-checkbox {
  width: 50%;
  margin-bottom: 8px;
}
.contentOther1:hover {
  color: #e52e00 !important;
}
/deep/.ant-list-pagination {
  margin-top: 40px;
  text-align: center;
}
/deep/.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: none;
}
.shaiXuanMore {
  position: absolute;
  right: 15px;
  top: 65px;
}
</style>
