<template>
  <MainCenter>
    <!-- 专家评选详情页 -->
    <div class="logo">logo</div>
    <div class="title">
      <h3 class="title_h3">{{ data ? data.title : '' }}</h3>
      <p>{{ data ? data.outtitle : '' }}</p>
      <p><RichText :content="data ? data.author : ''"  /></p>
      <p>{{ data ? data.classnames + '·' + data.postdate : '' }}</p>
    </div>
    <div class="main">
      <Share :isShow="{ navigation: true, collect: true, share: true, original: true }" :outlink="data.outlink"></Share>
      <div class="rich_text">
        <div class="rich_text_left">
          <div class="left_top">述评 NEJM—XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX经</div>
          <RichText class="left_content" :content="data.content" />
          <BzVideoPlayer
            src="https://video.699pic.com/videos/26/36/01/b_uiQTHWa8BkvX1615263601_10s.mp4"
            width="500px"
          />
        </div>
        <div class="rich_text_right">
          <Correlation :correlationList="correlationList" :correlation="correlation"></Correlation>
        </div>
      </div>
    </div>
  </MainCenter>
</template>

<script>
import MainCenter from '@/components/layout/MainCenter.vue'
import RichText from '@/components/layout/RichText.vue'
import Share from '@/components/share/Share.vue'
import Correlation from '@/components/layout/Correlation.vue'
import { selectArticleList, getArticleDetail } from '@/api/serach'
import BzVideoPlayer from '@/components/video/BzVideoPlayer.vue'
import moment from 'moment'
export default {
  name: 'ArticleParticulars',
  components: { RichText, BzVideoPlayer, MainCenter, Share, Correlation },
  data() {
    return {
      correlationList: null,
      correlation: {
        articleIds: [],
        pageNo: 1,
        pageSize: 10,
      },
      data: {},
    }
  },
  async created() {
    await getArticleDetail({ permalinks: this.$route.query.permalinks }).then((res) => {
      res.classnames = res.classnames.replace(/,/g, '')
      res.postdate = moment(res.postdate).format('YYYY-MM-DD')
      this.data = res
      this.data.content = this.data.content.replace(/<img/g, '<img style="max-width:820px;height:auto;"')
    })
    if (this.data?.relationArticleIds?.length > 0) {
      this.correlation.articleIds = this.data.relationArticleIds
      await selectArticleList(this.correlation).then((res) => {
        if (res) {
          res.results.forEach((element) => {
            element.postdate = moment(element.postdate).format('YYYY-MM-DD')
          })
          this.correlationList = res
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.main {
  overflow: hidden;
}
.logo {
  width: 178px;
  height: 40px;
  background: rgb(211, 210, 210);
  margin: 23px 0 34px 0;
}
.title {
  width: 100%;
  min-height: 106px;
  //   background: rgb(158, 158, 158);
  border-bottom: 1px solid rgb(172, 171, 171);
}
.title_h3 {
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
  position: relative;
}
.title_h3::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 16px;
  background: rgb(255, 0, 0);
}
.title p {
  margin: 10px 0;
}
.title p:nth-of-type(1),
.title p:nth-of-type(3) {
  font-size: 9px;
}
.title p:nth-of-type(2) {
  font-size: 7px;
  background: rgb(233, 228, 228);
  padding: 5px 5px;
}
.rich_text {
  width: 100%;
  margin-bottom: 20px;
}
.rich_text_left {
  width: 820px;
  float: left;
}
.left_content {
  width: 100%;
  overflow: hidden;
  line-height: 40px;
}
.left_top {
  width: 100%;
  height: 26px;
  line-height: 26px;
  color: rgb(255, 255, 255);
  background: rgb(255, 0, 0);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  padding-left: 5px;
}
.rich_text_right {
  width: 300px;
  min-height: 400px;
  float: right;
}
</style>
